import React, { Component } from "react";
import CardList from "../../cardList/cardList";
import StoryData from "../stories/storyList/storyData";

export default class RelatedStorySection extends Component {
  getStoryByIds(ids) {
    const data = ids.map((id) => {
      return StoryData.getDataById(id);
    });
    return data;
  }

  render() {
    debugger;
    return (
      <div className="page-section">
        <div className="page-container container">
          <h3 className="mb-5">Related Stories</h3>
          <CardList
            data={this.getStoryByIds(this.props.data)}
            gridLg={3}
            readMoreEnabled={true}
          />
        </div>
      </div>
    );
  }
}
