import { cn } from "../../lib/utils";
import React, { useState } from "react";
import "./ContentTabs.scss";

const ContentTabs = ({ sections, onTabClick }) => {
  const [selected, setSelected] = useState(sections[0]);

  const tabClick = (section) => {
    setSelected(section);
    onTabClick(section);
  };

  return (
    <div
      className="d-inline-flex align-items-center gap-3 "
      id="ComponentContentTabs"
    >
      {sections.map((section) => {
        return (
          <div
            className={cn(
              "d-flex flex-column justify-content-center gap-3 cursor-pointer contentTab px-5 py-3 rounded-3 cursor-pointer",
              selected.title === section.title
                ? "active border shadow-sm"
                : "bg-body-tertiary"
            )}
            key={section.title}
            onClick={() => tabClick(section)}
          >
            {section.icon && (
              <span className="d-inline section-icon mx-auto">
                {section.icon}
              </span>
            )}
            <h5 className="contentTitle m-0">{section.title}</h5>
          </div>
        );
      })}
    </div>
  );
};

export default ContentTabs;
