import React, { Component } from "react";
import "./getStartedSection.scss";
import CtaButtons from "../ctaButtons/ctaButtons";

export default class GetStartedSection extends Component {
  render() {
    return (
      <>
        <div className="page-section" id="getStartedSection">
          <div
            className="page-container container p-5 bg-img-no-repeat bg-img-size-cover"
            style={{
              backgroundImage: "url(/images/randomCirclesGradientBg.svg)",
            }}
          >
            <div className="row justify-content-center py-5">
              <div className="col-lg-8 text-center">
                <h2 className="text-light">
                  Get the power of futuristic Data & AI Platform for your
                  enterprise.
                </h2>
                <div className="d-flex gap-3 align-items-center w-100 justify-content-center">
                  <CtaButtons />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
