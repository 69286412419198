import React, { Component } from "react";
import MultiLevelAccordionNavItem from "../../multilevelAccordionNavItems/multiLevelAccordionNavItems";
import StoryData from "./storyList/storyData";
import CardList from "../../cardList/cardList";
import "./stories.scss";
import GetStartedSection from "../../getStartedSection/getStartedSection";
import CtaButtons from "../../ctaButtons/ctaButtons";

export default class Stories extends Component {
  constructor(props) {
    debugger;
    super(props);
    this.state = {
      storyData: [],
      refreshCardList: true,
    };
    this.accordionRef = React.createRef();
  }

  static getLinks() {
    return [
      {
        id: "industry",
        title: "By Industry",
        dropdown: [
          {
            id: "financial-services",
            title: "Financial Services",
          },
          {
            id: "retail-services",
            title: "Retail and CPG",
          },
        ],
      },
      {
        id: "role",
        title: "By Role",
        dropdown: [
          {
            id: "data-analyst",
            title: "Data Analyst",
          },
          {
            id: "data-scientist",
            title: "Data Scientist",
          },
          {
            id: "data-engineer",
            title: "Data Engineers",
          },
        ],
      },
    ];
  }

  onNavItemClick(obj) {
    obj = this.setNavSelections(obj);
    this.changeUrl(obj);
  }

  setNavSelections(obj) {
    this.accordionRef.current.selectItems(obj);
    return obj;
  }

  changeUrl(params) {
    const url =
      "/stories?" +
      Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
    this.props.history.push(url);
  }

  componentDidMount() {
    this.listenToRouteChanges();
    this.updateStoryData();
    // this.selectNavItemsOnLoad();
  }

  componentWillUnmount() {
    this.routeListener();
  }

  selectNavItemsOnLoad() {
    const obj = this.getSearchParams();
    this.setNavSelections(obj);
    this.onNavItemClick(obj);
  }

  listenToRouteChanges() {
    if (this.props.history) {
      this.routeListener = this.props.history.listen(() => {
        this.onRouteChange();
      });
    }
  }

  onRouteChange() {
    debugger;
    this.updateStoryData();
  }

  updateStoryData() {
    const searchTags = this.getSearchParams();
    let storyData;
    if (searchTags.industry.length === 0 && searchTags.role.length === 0) {
      storyData = StoryData.getAll();
    } else {
      storyData = StoryData.getDataByIndustriesAndRoles(searchTags);
    }

    this.setState({
      storyData: storyData,
      refreshCardList: !this.state.refreshCardList,
    });
  }

  getSearchParams() {
    const search = this.props.history.location.search;
    const params = new URLSearchParams(search);
    const result = {};
    let industry = params.get("industry");

    if (industry) {
      result.industry = industry.split(",");
    } else {
      result.industry = [];
    }

    let role = params.get("role");

    if (role) {
      result.role = role.split(",");
    } else {
      result.role = [];
    }

    return result;
  }

  onListItemClickCb(data) {
    debugger;
    this.props.history.push(`/story/${data.title}`);
  }

  render() {
    return (
      <div className="page position-relative" id="stories-page">
        <div className="h-100 page-section">
          <div className="container hero-container page-container">
            <div className="row align-items-start justify-content-between g-5">
              <div className="col-xl-5 col-lg-6 col-md-12">
                <h1>Stories</h1>
                <p className="fs-5 mb-5">
                  Learn about the current data challenges posed in various
                  industries and how Cynepia provides creative and unique
                  solutions.
                </p>
                <CtaButtons />
              </div>
              <div className="col-xl-7 col-lg-6 col-md-12">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/storiesBgImage.jpg"
                    alt="cynepia stories"
                    width="100%"
                    className="rounded-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="body-section page-section">
          <div className="page-container container">
            <div className="row">
              <div className="col-lg-3">
                <div className="filter-section">
                  <h4>Filter</h4>
                  <MultiLevelAccordionNavItem
                    links={Stories.getLinks()}
                    clickCb={(obj) => this.onNavItemClick(obj)}
                    multiselect={true}
                    ref={this.accordionRef}
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <CardList
                  key={this.state.refreshCardList}
                  data={this.state.storyData}
                  gridLg={2}
                  readMoreEnabled={true}
                />
              </div>
            </div>
          </div>
        </div>
        <GetStartedSection />
      </div>
    );
  }
}
