import React, { Component } from "react";
import InputWithButton from "../inputWithButton/inputWithButton";
import "./subscribeSection.scss";
import { CheckCircleOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";

export default class SubscribeSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
      requestOnGoing: false,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    debugger;
    let bodyParams = {
      email: e.target.elements.email.value,
    };
    let url = window.__SubscribeNewsletter__;
    var params = JSON.stringify(bodyParams);

    fetch(url, {
      method: "POST",
      body: params,
    })
      .then((res) => res.json())
      .then((res) => {
        let formSubmitted;
        if (res.status === 200) {
          formSubmitted = true;
        } else {
          alert("Subscribe Failed. Please try again. !");
          console.error(res);
        }
        this.setState({
          formSubmitted,
          requestOnGoing: false,
        });
      });
  }

  render() {
    return (
      <div
        className="page-section border-bottom border-light container"
        id="subscribeSection"
      >
        <div className="page-container container ">
          <div className="row g-5 align-items-center justify-content-between">
            <div className="col-lg-6">
              <h2 className="text-primary mb-2">Get insights delivered</h2>
              <h2>Right in your inbox</h2>
            </div>
            <div className="col-lg-5">
              <div
                className={`form-section ${
                  this.state.formSubmitted ? "d-none" : "d-block"
                }`}
              >
                <form onSubmit={(e) => this.handleSubmit(e)}>
                  <div className="mb-3 d-flex cyn-form">
                    <InputWithButton
                      inputPlaceholder="Enter your work email"
                      inputType="email"
                      required={true}
                      inputName="email"
                      buttonType="submit"
                    />
                  </div>
                </form>
                <small className="text-secondary">
                  By submitting this form you agree to our{" "}
                  <Link to="/privacy">Privacy Policy</Link> and our{" "}
                  <Link to="/terms">Terms of Use</Link>.
                </small>
              </div>
              <div
                className={`message-section p-4 ${
                  this.state.formSubmitted ? "d-block" : "d-none"
                }`}
              >
                <div className="d-flex gap-3">
                  <div className="">
                    <CheckCircleOutline style={{ fill: "#ffffff" }} />
                  </div>
                  <div className="text-light">
                    Thank you for subscribing to our newsletter.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
