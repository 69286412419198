import React, { Component } from "react";
import CtaButtons from "../ctaButtons/ctaButtons";
export default class ContactUsSection extends Component {
  render() {
    return (
      <div className="page-section">
        <div className="page-container container accent-bg-2 p-5">
          <div className="row g-5">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h3>Still confused ? Let us help you.</h3>
              <div className="mb-5">
                Fill out your details and our sales representive will reach you
                and help you with your queries.
              </div>
              <div className="cta-wrapper d-flex gap-3 align-items-center">
                <CtaButtons size={"regular"} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center justify-self-center">
              <div className="img-wrapper text-center">
                <img
                  src="/images/contactUsEmailVector.svg"
                  alt="cynepia contact us"
                  width="40%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
