import React, { useState } from "react";
import ContentTabs from "../../../ContentTabs/ContentTabs";
import CreateAgentFlow from "./CreateAgentFlow";
import CustomAgentsSection from "./CustomAgentsSection";

const ContentTabbedSection = () => {
  const sectionTabs = [
    {
      id: 1,
      title: "Create your own agents",
      Component: <CreateAgentFlow />,
    },
    {
      id: 2,
      title: "Pre-built agents",
      Component: <CustomAgentsSection />,
    },
  ];

  const [ActiveSection, setActiveSection] = useState(sectionTabs[0]);

  const onTabClick = (section) => {
    setActiveSection(section);
  };

  return (
    <div>
      <div className="contentTabsSection d-flex justify-content-center">
        <ContentTabs
          sections={sectionTabs}
          onTabClick={onTabClick}
        ></ContentTabs>
      </div>
      {ActiveSection.Component}
    </div>
  );
};

export default ContentTabbedSection;
