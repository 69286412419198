import React, { Component } from "react";
import CardItem from "./cardItem";

export default class CardList extends Component {
	constructor(props) {
		super(props);
		this.settings = CardList.mergeDefaults(props);
	}

	static mergeDefaults(props) {
		const defaults = {
			gridLg: 3,
			gridMd: 2,
			gridSm: 1,
			data: [],
			readMoreEnabled: true,
			listItemClickCb: null,
		};
		const mergedDefaults = { ...defaults, ...props };
		mergedDefaults.gridClassNames = CardList.getGridClassNames(mergedDefaults);
		return mergedDefaults;
	}

	static getGridClassNames(props) {
		const { gridLg, gridMd, gridSm } = props;
		return (
			"col-lg-" +
			12 / gridLg +
			" " +
			"col-md-" +
			12 / gridMd +
			" " +
			"col-sm-" +
			12 / gridSm
		);
	}

	render() {
		return (
			<div className="container-fluid">
				<div className="row g-4">
					{this.settings.data.map((data, i) => (
						<div key={i} className={this.settings.gridClassNames + " "}>
							<CardItem
								key={i}
								data={data}
								readMoreEnabled={this.settings.readMoreEnabled}
							/>
						</div>
					))}
				</div>
			</div>
		);
	}
}
