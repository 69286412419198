import React, { Component } from "react";
// import FeatureListing from "../../featureListing";

import { Link } from "react-router-dom";
import GetStartedButton from "../../../../ctaButtons/getStartedButton";

export default class IndustrySpecific extends Component {
  static getFeaturesList() {
    return {
      data: [
        {
          title: "Financial Services",
          description:
            "Combine data from all of your banking applications. Solve growth vs risk conundrum, create customer 360 personas to stregthen customer relations",
          link: "/stories?industry=financial-services",
          imgSrc: "/images/icons/finance.svg",
          cta: {
            title: "Read More",
          },
        },
        {
          title: "Retail & CPG",
          description:
            "Drive growth, streamline operations, cross-sell/up-sell while creating unique shopper experiences.",
          link: "/stories?industry=retail-services",
          imgSrc: "/images/icons/cart.svg",
          cta: {
            title: "Read More",
          },
        },
      ],
    };
  }

  render() {
    const list = IndustrySpecific.getFeaturesList().data;
    return (
      <>
        <div className="list-wrapper d-flex flex-direction-column h-100">
          <div className="list-heading-wrapper d-flex gap-3 mb-5">
            <span className="list-icon-wrap ">
              <img
                src="/images/icons/industry.svg"
                alt="cynepia industry icon"
                width="30"
              />
            </span>
            <span>
              <h5>By Industry</h5>
            </span>
          </div>
          <div className="list mb-3">
            {list.map((data, i) => {
              const { imgSrc, title, description, link } = data;
              return (
                <div key={i} className="list-item d-flex gap-3">
                  <span className="list-icon-wrap ">
                    <img src={imgSrc} alt="cynepia" width="30" />
                  </span>
                  <div className="list-item-body">
                    <h6>
                      <Link to={link}>{title}</Link>
                    </h6>
                    <p className="medium-text text-secondary">{description}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-auto">
            <GetStartedButton />
          </div>
        </div>
      </>
    );
  }
}
