import React, { Component } from "react";
import "./dropdownSection.scss";

export default class DropdownSection extends Component {
  constructor(props) {
    super(props);
    this.isActive = false;
    this.ref = React.createRef();
    this.onWindowClick = this.onWindowClick.bind(this);
    this.onDropdownClicked = this.onDropdownClicked.bind(this);
  }

  componentDidMount() {
    window.addEventListener("mouseLeave", () => {
      this.onWindowMouseLeave();
    });
  }

  onWindowMouseLeave() {
    this.props.onDropdownHiddenCallback();
  }

  toggleDropdown() {
    const nav = this.ref.current;
    nav.classList.toggle("active");
  }

  showOrHideDropdown(status) {
    return status ? this.showDropdown() : this.hideDropdown();
  }

  showDropdown() {
    this.ref.current.classList.add("active");
    this.addClickEvents();
  }

  hideDropdown() {
    this.ref.current.classList.remove("active");
    this.removeClickEvents();
  }

  onDropdownClicked(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  addClickEvents() {
    window.addEventListener("click", this.onWindowClick, false);
    // this.ref.current.addEventListener("click", this.onDropdownClicked);
  }

  removeClickEvents() {
    window.removeEventListener("click", this.onWindowClick, false);
    // this.ref.current.addEventListener("click", this.onDropdownClicked);
  }

  onWindowClick(e) {
    if (e.target.closest(".nav-dropdown")) {
      e.preventDefault();
      return;
    }
    e.stopPropagation();
    // this.hideDropdown();
    this.props.onDropdownHiddenCallback();
  }

  render() {
    return (
      <div
        className="nav-dropdown-wrapper"
        ref={this.ref}
        onMouseLeave={() => this.props.onDropdownHiddenCallback()}
      >
        <div className="dropdown-overlay"></div>
        <div className="scroll-container">{this.props.childComp}</div>
      </div>
    );
  }
}
