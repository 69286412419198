import React from "react";
import ListComponent from "../../../listComponent/listComponent";

const CustomAgentsSection = () => {
  return (
    <>
      <div className="page-section">
        <div className="page-container container">
          <div className="row align-items-start g-5">
            <div className="col-xxl-6 col-lg-6">
              <h6 className="pre-heading-highlight fw-bold">
                Pre-built Agents
              </h6>
              <h3>Data Agents for various roles and tasks</h3>
              <ListComponent
                data={[
                  "Unified approach accelarates building agents for all data roles",
                  "Xceed Intelligence framework enables building, augmenting and customizing data agents for various tasks/processes",
                  "Jane, the business analyst agent provides conversational experience for getting answers and building dashboards",
                  "Coming soon, Liam the Data Engineer Agent and Mary, A Data Science Agent...",
                ]}
              />
            </div>
            <div className="col-xxl-6 col-lg-6">
              <div className="img-wrapper text-center">
                <img
                  src="/images/dataAgentList.svg"
                  width="100%"
                  alt="Data agents for all roles"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section">
        <div className="page-container container ">
          <div className="row">
            <div className="col-xxl-6 col-lg-6">
              <div className="img-wrapper text-center">
                <img
                  src="/images/agentConversationPinup.svg"
                  width="90%"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xxl-6 col-lg-6">
              <h6 className="pre-heading-highlight fw-bold">
                Introducing Jane
              </h6>
              <h3>Your Data Analyst Agent</h3>
              <ListComponent
                data={[
                  "Conversational experience pre-integrated with Xceed Dashboards ensures best of AI/BI functionality",
                  "Semantic index is automatically refreshed on a catalog/metadata udpate",
                  "In-build vector database and search",
                  "Data teams can influence agent performance and reliability by improving their knowledge base",
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="page-section">
        <div className="page-container container">
          <div className="row g-5">
            <div className="col-xxl-5 col-lg-6">
              <h6 className="pre-heading-highlight fw-bold">
                Monitor Agent Performance in Real Time
              </h6>
              <h3>Monitor and Take Action</h3>
              <ListComponent
                data={[
                  `Track overall performance of agents across threads/sessions`,
                  `Track consumption by users, task or sessions`,
                  "Drill down into specific failures and review the conversation",
                  "Take actions by influencing trusted examples to correct agents behavior on specific issue",
                ]}
              />
            </div>
            <div className="col-xxl-7 col-lg-6">
              <div className="img-wrapper text-center">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/monitoring_details.svg"
                    width="90%"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomAgentsSection;
