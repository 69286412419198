import React, { Component } from "react";

export default class KnowYourCustomer extends Component {
  render() {
    return (
      <div className="page-section">
        <div className="page-container container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="page-section">
                <div className="page-container container-flui">
                  <h2>Challenge</h2>
                  <p>
                    Too much time discovering trusted data assets (including
                    monthly data) across various individual repositories across
                    various departments and SAAS applications.
                  </p>
                  <p>
                    The data analyst team and business users needed a more
                    efficient way to discover data & query datasets and find
                    answers to data questions. Often getting answers to data
                    wasn’t easy, since data was stored in various siloed
                    repositories. The problem becomes particularly complex and
                    challenging, in a high growth company with multiple
                    departments each using quite a few different saas tools and
                    each generating data at a rapid velocity. Number of datasets
                    usually grow to hundreds and even thousands across these
                    siloed databases/applications making it highly inefficient
                    for a data user or data analyst to discover or find the
                    data. Often you need to know who to ask or which team within
                    the organisation has access to the data you need. Even if
                    you were to find out and have access to the required data
                    source, there often are issues with trust worthiness of a
                    given data snapshot, since the historical states are often
                    archived and stored and person who can vet such data
                    snapshots may not be accessible, leaving the possibility of
                    picking an inaccurate or mismanaged dataset.
                  </p>
                </div>
              </div>
              <div className="page-section">
                <div className="page-container">
                  <h2>Solution</h2>
                  <h5>Home for all your data assets</h5>
                  <p>
                    Automated Xceed Data Catalog acts as a central interface for
                    accessing any dataset across the company. Be it raw tables
                    from various databases, or csvs or files from local or cloud
                    repository. Once connected/uploaded to Xceed Data Lake,
                    Xceed Analytics automatically learns the metadata/schema of
                    the dataset ingested, which can further be updated by the
                    data engineer/analyst/owner for any changes. Post the
                    initial version of the metadata. Data
                    Owner/Analyst/Engineers can further document in detail and
                    add tags to ensure any one who has access to dataset can
                    quickly understand what is the dataset about.
                  </p>
                  <p>
                    Xceed Data Workflow Outputs are automatically updated and
                    accessible via the Data Catalog instantly.
                  </p>
                  <h5>Deeper Customer Insights</h5>
                  <p>
                    Uncover the relationships between your customers and
                    products. Understand what makes shoppers tick and deliver
                    relevancy at every stage of their journey.
                  </p>
                  <p>
                    Our platform enables detailed customer behaviour analytics
                    without the need to code. Analyse historic and real-time
                    customer behaviour whilst understanding key drivers and
                    predicting what's next.
                  </p>
                  <p>
                    Create detailed customer level attributes based on what
                    they're buying, browsing and clicking on. Attributes are the
                    foundations smart segments are built upon.
                  </p>
                  <h5>Understand Customer Needs Better</h5>
                  <p>
                    Create detailed customer need states and missions across
                    every category based on how they shop, browse and interact
                    with your products.
                  </p>
                  <p>
                    Understand the deep relationships between products; within
                    and across hierarchies. Discover how your customers interact
                    with them, substitute them and purchase them together in the
                    same basket.
                  </p>
                  <p>
                    Create interactive customer decision trees to identify
                    consumers' decision making process across every category and
                    sub-category using our platform.
                  </p>
                  <p>
                    Identify clusters of products that forming each customer
                    mission and need state, with detailed product attributes and
                    customer behaviours describing the products that define each
                    mission.
                  </p>
                  <h5>Segment Customers Better</h5>
                  <p>
                    Create and write macro, micro and individual customer
                    segments at the click of a button. Instantly identify the
                    defining characteristics of each segment.
                  </p>
                  <p>
                    Visualise customer data and insight in real-time whilst
                    creating smart segments - banishing the need for endless
                    iterations and rebuilds.
                  </p>
                  <p>
                    Take advantage of advanced data science techniques, machine
                    learning and AI with our easy to use, highly visual, no-code
                    platform.
                  </p>
                  <p>
                    Create specific audiences from segments using our decision
                    engine, then deliver unique, highly relevant customer
                    journeys and recommendations.Governed Data Assets
                  </p>
                </div>
              </div>
              <div className="page-section">
                <div className="page-container">
                  <h2>Summary</h2>
                  <p>
                    In summary, an integrated data catalog not just acts as a
                    central knowledge repository of all your data assets and
                    make it easily for every one who needs access to find,
                    search and discover data assets, It eliminates the manual
                    process by providing a workflow integrated data catalog
                    ensuring, you get rid of manual process and thereby don’t
                    create technical debt by having to integrate disparate data
                    apps with the data catalog.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
