import React, { Component } from "react";
import { Link } from "react-router-dom";
import AnimatedArrowIconLink from "../animatedArrowIconLink/animatedArrowIconLink";

export default class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  handleAccordionClick(e) {
    this.setState({
      active: !this.state.active,
    });
    e.currentTarget.scrollIntoView({ behavior: "smooth" });
  }

  handleNavItemClick(e) {
    const dataset = e.currentTarget.dataset;
    e.currentTarget.classList.toggle("selected");
    this.props.clickCb(dataset.id);
  }

  getLink(item) {
    const hasDropdown = item.dropdown !== undefined;
    if (hasDropdown) {
      return (
        <>
          <div
            className={
              "title w-100 link accordionTitle d-flex align-items-center justify-content-between cursor-pointer " +
              (this.state.active ? "active " : "")
            }
            onClick={(e) => this.handleAccordionClick(e)}
          >
            {item.title}
            <span className="nav-drop-icon-wrapper">
              <span className="arrow-icon">
                <span></span>
                <span></span>
              </span>
            </span>
          </div>
          <div className={"accordion" + (this.state.active ? "active " : "")}>
            <div className="content m-3">
              {item.dropdown.map((i) => {
                return (
                  <div className="nav-link border-none" key={i.id}>
                    {i.to ? (
                      i.target === "_blank" ? (
                        <span
                          className="link d-block"
                          data-id={i.id}
                          key={i.id}
                          onClick={() => {
                            window.open(i.to, "_blank");
                          }}
                        >
                          <AnimatedArrowIconLink content={i.title} />
                        </span>
                      ) : (
                        <Link
                          to={i.to}
                          data-id={i.id}
                          key={i.id}
                          className="link d-block"
                        >
                          {i.title}
                        </Link>
                      )
                    ) : (
                      <span
                        className="link d-block"
                        data-id={i.id}
                        data-category={item.id}
                        key={i.id}
                        onClick={(e) => {
                          this.handleNavItemClick(e);
                        }}
                      >
                        {i.title}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {item.to ? (
            <Link
              to={item.to || "/"}
              data-id={item.id}
              key={item.id}
              className="link"
            >
              {item.title}
            </Link>
          ) : (
            <span
              className="link d-block"
              data-id={item.id}
              key={item.id}
              onClick={() => {
                this.props.clickCb(item.id);
              }}
            >
              {item.title}
            </span>
          )}
        </>
      );
    }
  }

  render() {
    const item = this.props.item;
    return (
      <li ref={this.navItemRef} className={"nav-link"}>
        {this.getLink(item)}
      </li>
    );
  }
}
