import React, { Component } from "react";
import ContactUsSection from "../../../contactUsSection/contactUsSection";

export default class DataAnalyst extends Component {
	render() {
		return (
			<div className="page" id="dataEngineerRolePage">
				<div className="page-section">
					<div className="page-container container hero-container">
						<div className="row justify-content-center">
							<div className="col-lg-8 text-center">
								<h1>Xceed Analytics for Business Analyst</h1>
								<p className="fs-6">
									Deliver insights to broad range of stackholders by getting
									answers to their complex business questions/situations. Make
									it happen efficiently, effectively and in a timely manner
									using Xceed Analytics.
								</p>
								<p className="fs-6">
									Don’t just stop at descriptive analysis, step-up and deliver
									predictive analysis to the business stackholders using Xceed
									Analytics no-code AutoML solution.
								</p>
							</div>
							<div className="col-lg-12">
								<img
									src="/images/dataAnalystRole.png"
									width="100%"
									alt="data Scientist"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className="page-section">
								<div className="page-container container">
									<h3>Integrate Data Quickly from across data sources</h3>
									<div>
										<p>
											<span className="me-2">1.</span>Translate your business
											objective/goals into reality in hours.
										</p>
										<p>
											<span className="me-2">2.</span> Integrate any data faster
											using Xceed Analytics. Upload offline files.e.
										</p>
									</div>
								</div>
							</div>
							<div className="page-section">
								<div className="page-container container">
									<h3>
										Perform Exploration, Create new KPIs, Data Models &
										Dashboard
									</h3>
									<div>
										<p>
											<span className="me-2">1.</span>
											Create & Explore new/existing datasets using Xceed
											Analytics SQL Interface.
										</p>
										<p>
											<span className="me-2">2.</span>
											Prepare, Cleanup, Combine with other offline and online
											data sources seamlessly using Xceed Analytics Workflow
											Designer. Publish new data models within minutes to data
											catalog for building dashboards and reports.
										</p>
										<p>
											<span className="me-2">3.</span>
											Use Self-service dashboard to translate your visualization
											plan to actual dashboard.
										</p>
									</div>
								</div>
							</div>
							<div className="page-section">
								<div className="page-container container">
									<h3>Share & Collaborate</h3>
									<div>
										<p>
											<span className="me-2">1.</span>
											Collaborate and share instantly with relavant
											stackholders.
										</p>
										<p>
											<span className="me-2">2.</span>
											Iterate quickly for the feedback received.
										</p>
									</div>
								</div>
							</div>
							<div className="page-section">
								<div className="page-container container">
									<h3>Go Further</h3>
									<div>
										<p>
											<span className="me-2">1.</span>
											Don’t stop just at descriptive statistics and KPI. Step up
											and use Xceed Analytics Visual Model Builder to deliver
											predictive insights.
										</p>
									</div>
								</div>
							</div>
							<ContactUsSection />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
