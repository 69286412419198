import React, { Component } from "react";
import "./dataEngineeringAndMl.scss";
import ContactUsSection from "../../../contactUsSection/contactUsSection";
import CtaButtons from "../../../ctaButtons/ctaButtons";
import ListComponent from "../../../listComponent/listComponent";

export default class DataEngineeringAndMl extends Component {
  render() {
    return (
      <div className="page" id="dataEngineeringAndMlPage">
        <div className="page-section">
          <div className="page-container container hero-container">
            <div className="row align-items-center g-3">
              <div className="col-lg-12 col-md-12 text-center mb-4">
                <h1 className="mb-3">Build, Run, Maintain {"&"} Collaborate</h1>
                <p className="fs-5 mb-4">
                  Get your data workflows to production in minutes.
                </p>
                <CtaButtons center={true} />
              </div>
              <div className="col-lg-12  col-md-12 h-100 right-section">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataPreparationBanner.svg"
                    width="80%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row">
              <div>
                <h2>Overview</h2>
                <p className="fs-5">
                  Build data pipelines with Xceed Visual Designer — a no code
                  way to build and manage data pipelines and create golden
                  datasets for ML and Analytics use cases. Xceed Visual
                  Designers helps users without minimal or no technical/coding
                  skills become data engineers by simplifying building, managing
                  and deploying data pipelines. Stop worrying about deployment,
                  language syntax, scalability & productionizing. We have it all
                  covered. Extract value off the raw data in datalake and create
                  high quality data models for your use cases. Share data models
                  instantly effortlessly between ML and Analytics team.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-start g-5">
              <div className="col-xxl-5 col-lg-5">
                <h6 className="pre-heading-highlight fw-bold">
                  BETTER ROI ON YOUR BIG DATA PROJECTS
                </h6>
                <h3>
                  Empower everyone with the power of low code data
                  transformation
                </h3>
                <ListComponent
                  data={[
                    "No need for coding and deployment skills. Any one can be a data engineer.",
                    "No hassle of maintaining code, revisions etc",
                    "Create high quality data models for your valuable use cases effortlessly and efficiently.",
                    "Publish and deploy instantly for Analytics or ML use cases.",
                  ]}
                />
              </div>
              <div className="col-xxl-7 col-lg-7">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataPreparationVisual1.svg"
                    width="100%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container ">
            <div className="row">
              <div className="col-xxl-7 col-lg-7">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataPreparationVisual2.svg"
                    width="90%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
              <div className="col-xxl-5 col-lg-5">
                <h6 className="pre-heading-highlight fw-bold">
                  No code / Low code platform
                </h6>
                <h3>Build Data Pipelines</h3>
                <ListComponent
                  data={[
                    "Create Resuable Multistep Data Ingenstion Pipeline And Store the Transformed Data Back Into Datalake.",
                    "Virtualize and Store it directly into datalake",
                    "Make the transformed data available instantly via Enterprise Data Catalog for Consumption",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row g-5">
              <div className="col-xxl-5 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                  Collaborate Between Data Science & BI Teams
                </h6>
                <h3>Govern, Share and Collaborate</h3>
                <ListComponent
                  data={[
                    "Share your data models across all the data teams who need access to your model instantly.",
                    "Ensure single source of truth across use cases",
                    "Never start from scratch. Reuse and Build newer models on top of existing models.",
                  ]}
                />
              </div>
              <div className="col-xxl-7 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataPreparationVisual3.svg"
                    width="90%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row g-5">
              <div className="col-xxl-7 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataPreparationVisual4.svg"
                    width="100%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
              <div className="col-xxl-5 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                  Automate To Accelarate
                </h6>
                <h3>Automate Data Engineering Tasks</h3>
                <ListComponent
                  data={[
                    "Schedule data pipelines with Xceed Workflow Jobs for end-to-end production-ready pipelines.",
                    "Accelarate time to value for your data projects.",
                    "Trigger downstream dashboard and model refresh on successful job completion.",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <h3 className="mb-5">Technical Capabilties</h3>
            <div className="row g-3 align-items-strech justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/singleSourceOfTruth.svg"
                        alt="cynepia data connector"
                        width={75}
                      />
                    </div>
                    <h5 className="title">No Code</h5>
                    <div className="description">
                      Visual Designer enables users to create modular, fairly
                      complex data pipelines without writing single line of
                      code.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/visual_flow_representation.svg"
                        alt="cynepia data connector"
                        width={75}
                      />
                    </div>
                    <h5 className="title">Visual Flow Representation</h5>
                    <div className="description">
                      Visual workflow representation ensures readable,
                      documented data pipelines all the time.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/data_preparation/jupyter.svg"
                        alt="cynepia historical snapshot"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Write Python Code</h5>
                    <div className="description">
                      Data engineers can write custom python logic like before
                      where ever required.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/peopleGroup.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Collaborate & Govern</h5>
                    <div className="description">
                      Single source of truth ensures better collaboration
                      between data engineers, business analyst and data
                      scientists.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/badge.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Ensure Reliability & Quality</h5>
                    <div className="description">
                      Fetch Data Profile at every step to ensure accuracy and
                      correctness of final outcome.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/modular_and_flexible.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Modular & Flexible</h5>
                    <div className="description">
                      Flexibly change to adapt to changing business logic over
                      time.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/pipeline.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Automate Data Pipeline Jobs</h5>
                    <div className="description">
                      Run Periodically or Trigger Pipeline Job on Input Dataset
                      Update, Alert stackholders for any pipeline failures.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/versionedDataModels.svg"
                        alt="cynepia data connector"
                        width={70}
                      />
                    </div>
                    <h5 className="title">Versioned Data Models</h5>
                    <div className="description">
                      Pre-integrated with datalake and data catalogue ensures
                      output versions are trackable and auditable.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/debug.svg"
                        alt="cynepia data connector"
                        width={60}
                      />
                    </div>
                    <h5 className="title">Troubleshoot & Debug</h5>
                    <div className="description">
                      A wide range of tools to help trouble shoot the pipeline
                      including warnings, column profile, run logs.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactUsSection />
      </div>
    );
  }
}
