import React, { Component } from "react";
import CynepiaClientsQuotations from "../../cynepiaClientsQuotations/cynepiaClientsQuotations";
import GetStartedSection from "../../getStartedSection/getStartedSection";
import ListComponent from "../../listComponent/listComponent";
import CtaButtons from "../../ctaButtons/ctaButtons";
import "./aboutUs.scss";

export default class AboutUs extends Component {
  render() {
    return (
      <div className="page position-relative" id="about-us-page">
        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-center g-3 justify-content-center">
              <div className="col-xl-8 col-lg-8 col-md-12 text-center">
                <h1 className="">Our Story</h1>
                <p className="fs-5 mb-5">
                  Cynepia was founded on the belief that data is the single most
                  important factor that determines the ability of businesses to
                  compete, manage their customer relationships, and transform
                  and transition to lead in the future.
                </p>
                <CtaButtons center={true} />
              </div>
            </div>
            <div className="col-sm-12 h-100 my-5 page-section">
              <div
                className="container hero-container page-container  p-5
                            bg-img-size-cover
                            bg-img-position-center
                        "
                style={{
                  backgroundImage: `url("/images/glassBlockBgAbstract.jpg")`,
                  minHeight: "70vh",
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-start">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h6 className="pre-heading-highlight fw-bold box">
                  The problem
                </h6>
                <h2>Current market challenge</h2>
                <p>
                  With the adoption of a modern data stack, the architecture for
                  DATA/ML has become more complex than ever before, and
                  enterprises experience a variety of issues, including the
                  complexity of integrating a multitude of bespoke tools, as
                  well as the difficulties in keeping such bespoke tools
                  running.
                </p>
                <p>
                  Maintaining the modern data stack and keeping it running was
                  never going to be easy, and with the shortage of data
                  architects and engineers, enterprises are faced with a
                  challenging task of keeping these bespoke tools running.
                </p>
                <p>
                  In the midst of increasing consumption of data talent, many
                  mid-sized companies (MM/SME) are further struggling to keep
                  data flowing freely through multiple proprietary tools/systems
                  and make them interoperable and frictionless.
                </p>
                <p>
                  AT CYNEPIA, OUR MISSION is to enable enterprises address the
                  complexity of the modern data stack by providing a modern,
                  Data First, AI Platform which is governable, collaborative and
                  frictionless, brings the data builders and data consumers
                  togethers, thereby helping our customers turn data into value
                  rapidly.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="img-wrapper">
                  <img
                    src="/images/aboutUs1.png"
                    width="100%"
                    alt="cynepia about us"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-top g-5">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="img-wrapper">
                  <img
                    src="/images/aboutUs2.png"
                    width="100%"
                    alt="cynepia about us"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h6 className="pre-heading-highlight fw-bold box">
                  The Solution
                </h6>
                <h2>How are we doing it?</h2>
                <p>
                  Our approach with End to End Data First AI Platform helps
                  enterprises turn data into value rapidly. Xceed Analytics
                  translates our vision and imagination of what a Modern data
                  stack needs to deliver. We own the user experience and bring
                  the benefits of modern data stack to the larger enterprise
                  market including MM and SME segments.
                </p>
                <p>
                  The company is expanding its geographical footprint and
                  working to achieve its vision of becoming a global
                  organization.
                </p>
                <p>
                  In the midst of increasing consumption of data talent, many
                  mid-sized companies (MM/SME) are further struggling to keep
                  data flowing freely through multiple proprietary tools/systems
                  and make them interoperable and frictionless.
                </p>
                <p>
                  AT <b>CYNEPIA, OUR MISSION</b> is to enable enterprises
                  address the complexity of the modern data stack by providing a
                  modern, Data First, AI Platform which is governable,
                  collaborative and frictionless, brings the data builders and
                  data consumers togethers, thereby helping our customers turn
                  data into value rapidly.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-center">
              <h2>Benefits of our approach to our customers</h2>
              <ListComponent
                data={[
                  "Enterprises can now focus their energy on the data use cases and not on modern data stack architecture and complexities of bringing bespoke tools together.",
                  "We are single mindedly focused on reducing the friction and high cost of ownership of modern data architecture and eliminating the bottlenecks of sub-optimal architecture.",
                  "Help both data builders and data consumers collaborate and govern data together in a friction-less way.",
                  "Help enterprises govern and manage there data assets seamlessly.",
                ]}
              />
            </div>
          </div>
        </div>
        <CynepiaClientsQuotations />
        <div className="page-section">
          <div className="page-container container p-5">
            <div className="row justify-content-center">
              <h2>Where are we headed ?</h2>
              <p>
                We are growing our geographical footprint to be closer to our
                customers and be a leading player in the Data First AI Platform
                space.
              </p>
              <p>
                Our promise of the past to bring a future-ready and future-proof
                Data First AI Platform continues in the future as well. The twin
                foundation of best-in-class architecture and best-in-class user
                experience is what we continue to focus on from engineering
                execution wise. We are investing even more aggressively by
                further in attracting top talent who are passionate about
                solving the same.
              </p>
            </div>
          </div>
        </div>
        <GetStartedSection />
      </div>
    );
  }
}
