import React, { Component } from "react";
// import FeatureListItem from "../../featureListItem";
// import FeatureListing from "../../featureListing";

import { Link } from "react-router-dom";
export default class RoleSpecific extends Component {
  static getFeaturesList() {
    return {
      data: [
        {
          title: "Data Analyst",
          description:
            "Access information about the data you need from Data Catalog, Instantly Create Meaningful stories and share with Business Users.",
          link: "/stories?role=data-analyst",
          imgSrc: "/images/icons/dataAnalyst.svg",
          cta: {
            title: "Read More",
          },
        },
        {
          title: "Data Scientist",
          description:
            "Discover Data/Features from Data Catalogue, Create Expermiments using code or AutoML in hours,  Build and Publish/Deploy Model in days.",
          link: "/stories?role=data-scientist",
          cta: {
            title: "Read More",
          },
          imgSrc: "/images/icons/dataScientist.svg",
        },
        {
          title: "Data Engineers",
          description:
            "Combine data from different databases/applications, Prepare/Transform data using no-code ui, quickly create golden datasets and share with users who need the same.",
          link: "/stories?role=data-engineer",
          cta: {
            title: "Read More",
          },
          imgSrc: "/images/icons/dataEngineer.svg",
        },
        {
          title: "Business User",
          description:
            "Be on top of your business KPIs, Access Dashboards/Stories. Get notified about newly published dashboards/stories.",
          link: "/stories?role=business-user",
          cta: {
            title: "Read More",
          },
          imgSrc: "/images/icons/self-service-bi.svg",
        },
      ],
    };
  }

  render() {
    const list = RoleSpecific.getFeaturesList().data;
    return (
      <>
        <div className="list-wrapper d-flex flex-direction-column h-100">
          <div className="list-heading-wrapper d-flex gap-3 mb-5">
            <span className="list-icon-wrap ">
              <img
                src="/images/icons/multiUser.svg"
                alt="cynepia industry icon"
                width="30"
              />
            </span>
            <span>
              <h5>By Role</h5>
            </span>
          </div>
          <div className="list row">
            {list.map((data, i) => {
              const { imgSrc, title, description, link } = data;
              return (
                <div key={i} className="col-lg-6">
                  <div className="list-item d-flex gap-3">
                    <span className="list-icon-wrap ">
                      <img src={imgSrc} alt="cynepia" width="30" />
                    </span>
                    <div className="list-item-body">
                      <h6>
                        <Link to={link}>{title}</Link>
                      </h6>
                      <p className="medium-text text-secondary">
                        {description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
