import React, { Component } from "react";
import NavItem from "./navItem";

export default class NavItems extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: true,
		};
		this.navItemsRef = {};
		this.props.links.forEach((item) => {
			this.navItemsRef[item.id] = React.createRef();
		});
	}

	onDropdownHideOrShow(itemId, dropdownActive) {
		Object.keys(this.navItemsRef).forEach((key) => {
			if (key !== itemId) {
				if (dropdownActive) {
					this.navItemsRef[key].current.removeActive();
				} else {
					this.navItemsRef[key].current.checkIfCurrentRouteMatches();
				}
			}
		});
	}

	render() {
		return (
			<ul className="nav-links">
				{this.props.links.map((item, i) => {
					return (
						<NavItem
							item={item}
							index={i}
							key={i}
							onDropdownHideOrShow={(itemId, dropdownActive) =>
								this.onDropdownHideOrShow(itemId, dropdownActive)
							}
							history={this.props.history}
							ref={this.navItemsRef[item.id]}
							active="false"
						/>
					);
				})}
			</ul>
		);
	}
}
