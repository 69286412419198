import React, { Component } from "react";
import { Link } from "react-router-dom";
import ContactUsSection from "../../contactUsSection/contactUsSection";
import ListComponent from "../../listComponent/listComponent";
import "./pricing.scss";
import GetStartedButton from "../../ctaButtons/getStartedButton";

export default class Pricing extends Component {
  static getListData() {
    return [
      {
        title: "Free Trial",
        img: {
          src: "/images/pricingItem1.svg",
          width: "100",
        },
        description:
          "Ideal to try for two users & small dataset size. Trial limit is for 14 days.",
        listData: [
          <>
            User Limit (<u>2</u>)
          </>,
          <>Personalized Story-boards</>,
          <>Broader Connectivity</>,
          <>
            Data Catalog (<u>Limited Datasets</u>)
          </>,
          <>Wide Range of Visualization Options</>,
          <>Admin Interface for User Management</>,
          <>At the heart of your mobile stratergy</>,
          <>In-Memory analytics Engine</>,
          <>
            Data Size (<u>Upto 10GB</u>)
          </>,
          <>
            Individual Data Size (<u>Max 20MB</u>)
          </>,
        ],
      },
      {
        tag: "Most Popular",
        title: "SME",
        img: {
          src: "/images/pricingItem2.svg",
          width: "100",
        },
        description:
          "Ideal for less than 25 user & individual dataset size not exceeding 1 GB.",
        listData: [
          <>
            User Limit (<u>25</u>)
          </>,
          <>Personalized Story-boards</>,
          <>Broader Connectivity</>,
          <>
            Data Catalog (<u>Unlimited Datasets</u>)
          </>,
          <>Wide Range of Visualization Options</>,
          <>Admin Interface for User Management</>,
          <>At the heart of your mobile stratergy</>,
          <>In-Memory analytics Engine</>,
          <>
            Data Size (<u>Upto 100GB</u>)
          </>,
          <>
            Individual Data Size (<u>Max 500MB</u>)
          </>,
        ],
      },
      {
        title: "Enterprise",
        img: {
          src: "/images/pricingItem3.svg",
          width: "100",
        },
        description:
          "Built & priced optimally to suit the requirements based on # of users and size of data.",
        listData: [
          <>
            User Limit (<u>Unlimited</u>)
          </>,
          <>Personalized Story-boards</>,
          <>Broader Connectivity</>,
          <>
            Data Catalog (<u>Unlimited Datasets</u>)
          </>,
          <>Wide Range of Visualization Options</>,
          <>Admin Interface for User Management</>,
          <>At the heart of your mobile stratergy</>,
          <>In-Memory analytics Engine</>,
          <>
            Data Size (<u>Unlimited</u>)
          </>,
          <>
            Individual Data Size (<u>Unlimited</u>)
          </>,
        ],
      },
    ];
  }

  render() {
    return (
      <div className="page" id="pricing-page">
        <div className="page-section">
          <div className="container page-container mb-0 pb-0">
            <div className="row justify-content-center g-3">
              <div className="col-xl-6 col-lg-6 col-md-12 text-center">
                <h6 className="pre-heading-highlight">Pricing</h6>
                <h1>Choose a Plan that fit's your scale</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-end justify-content-center g-3">
              {Pricing.getListData().map((item, i) => {
                const { title, img, listData, tag } = item;
                const classNames = `d-flex flex-direction-column py-3 px-4 h-100 ${
                  tag ? "tagged" : ""
                }`;
                return (
                  <div
                    className="col-lg-4 col-md-4 col-sm-8 col-xs-12 "
                    key={i}
                  >
                    <div className={classNames}>
                      {tag ? (
                        <h6 className="pre-heading-highlight my-4">{tag}</h6>
                      ) : (
                        ""
                      )}
                      <h3 className="title fs-4 f-w-500 mb-4">{title}</h3>
                      <div className="img-icon-wrapper text-center mb-4">
                        <img
                          src={img.src}
                          alt="cynepia pricing"
                          width={img.width}
                        />
                      </div>
                      <div className="description mb-3">{item.description}</div>
                      <GetStartedButton classNames={"mb-4 w-100"} />
                      <div className="benefits-section mb-5">
                        <h5 className="mb-4">Key Benefits</h5>
                        <ListComponent data={listData} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <ContactUsSection />
      </div>
    );
  }
}
