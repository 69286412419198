import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class Page404 extends Component {
    render() {
        return (
            <div className="page position-relative" id="about-us-page">
                <div className="page-section">
                    <div className="page-container container">
                        <div className="text-center d-flex justify-content-center gap-5 flex-direction-column">
                            <h2 className="text-center mb-0">This page doesn't exists.</h2>
                            <div className="text-center">
                                <img 
                                    src="images/page404.svg"
                                    alt = "404"
                                    className="img-fluid"
                                />
                            </div>
                            <div className="col-xxl-12">
                                <h4>Something went wrong!</h4>
                                <p>This page doesn't exists or may have been moved.</p>
                                <p>Please go back to the home page.</p>
                                <Link to="/">
                                    <button className="button primary">Go Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
