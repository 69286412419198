import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router

const ContactUsButton = ({ classNames, children }) => {
  const buttonClassNames = `button basic ${classNames ? classNames : ""}`;
  return (
    <Link to="/get-started">
      <button className={buttonClassNames}>{children || "Contact Us"}</button>
    </Link>
  );
};

export default ContactUsButton;
