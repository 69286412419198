/* eslint-disable eqeqeq */
import React, { Component } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./listAccordion.scss";

export default class ListAccordion extends Component {
  constructor(props) {
    super(props);
    this.currentRef = React.createRef();
  }

  onAccordionItemClick(id) {
    debugger;
    this.setToActive(id);
    this.props.clickCb(id);
  }

  setToActive(id) {
    const accordionItems = this.currentRef.current.querySelectorAll(
      ".list-accordion-item"
    );
    accordionItems.forEach((item) => {
      item.classList.remove("active");

      if (item.dataset.id == id) {
        item.classList.add("active");
      }
    });
  }

  render() {
    return (
      <>
        <div
          className="list-accordion"
          ref={this.currentRef}
          id="list-accordion"
        >
          {this.props.data.map((item, index) => {
            const { DescriptionComp } = item;
            return (
              <div
                key={index}
                data-id={item.id}
                className="list-accordion-item py-3 cursor-pointer border-bottom "
                onClick={() => this.onAccordionItemClick(item.id)}
              >
                <div className="title justify-content-between d-flex align-items-center">
                  <h5 className="m-0">
                    <span className="me-3">0{index + 1}</span>
                    {item.title}
                  </h5>
                  <span className="accordionIcon d-flex align-items-center justify-content-center">
                    <KeyboardArrowDownIcon />
                  </span>
                </div>
                <div className="description ">
                  <DescriptionComp />
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
