import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./index.scss";

const ImageSlideshow = ({ images, timerLimit }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    startTimer();

    return () => {
      stopTimer();
    };
  }, [images.length, timerLimit]);

  const startTimer = () => {
    const newTimer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        if (prevIndex + 1 === images.length) {
          return 0;
        }
        return prevIndex + 1;
      });
    }, timerLimit);
    setTimer(newTimer);
  };

  const stopTimer = () => {
    clearInterval(timer);
    setTimer(null);
  };

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
    if (timer) {
      stopTimer();
    }
    startTimer();
  };

  return (
    <div className="slideshow-container">
      <div className="slideshow-images">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slide ${index === currentImageIndex ? "active" : ""}`}
          >
            <img src={image} alt={`Slide ${index + 1}`} className="img-fluid" />
          </div>
        ))}
      </div>
      <div className="dot-indicators">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentImageIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

ImageSlideshow.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  timerLimit: PropTypes.number.isRequired,
};

export default ImageSlideshow;
