import React, { Component } from "react";
import SocialIcons from "../../../socialIcons/socialIcons";
import "./learnDropdown.scss";
import { ArrowRight } from "@material-ui/icons";
import AnimatedArrowIconLink from "../../../animatedArrowIconLink/animatedArrowIconLink";

export default class CompanyDropdown extends Component {
  handleItemClick(name) {
    this.setState({ activeItem: name });
  }

  render() {
    return (
      <div
        className="nav-dropdown w-50 position-relative"
        id="nav-learn-dropdown"
      >
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-xxl-6 col-lg-6 bg-white ">
              <div className="p-4 h-100 position-relative">
                <h4>Learn</h4>
                <div className="list-wrapper">
                  <ul className="list-group list-unstyled">
                    <li
                      className="mb-3 cursor-pointer"
                      onClick={() => {
                        window.open(window.__DocumentationUrl__, "_blank");
                      }}
                    >
                      <AnimatedArrowIconLink content="Documentation" />
                    </li>
                    <li
                      className="mb-2 cursor-pointer"
                      onClick={() => {
                        window.open(window.__BlogUrl__, "_blank");
                      }}
                    >
                      <AnimatedArrowIconLink content="Blogs" />
                    </li>
                  </ul>
                </div>
                <div
                  className="position-absolute social-icon-wrapper"
                  style={{ bottom: 20, right: 0 }}
                >
                  <SocialIcons></SocialIcons>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-6 col-lg-6 bg-img-size-cover"
              style={{
                backgroundImage: `url(images/risk_assesment.jpg)`,
              }}
            >
              <div className="p-4">
                <div className="">
                  <div className="mb-4">
                    <span
                      className="small-text fw-bolder rounded 
                                            bg-white px-3 py-2 
                                            opacity-90
                                        "
                    >
                      FEATURED
                    </span>
                  </div>
                  <div
                    className="list-wrapper 
                                        medium-text-all-descendant
                                        bg-white p-4 rounded 
                                        opacity-90
                                    "
                  >
                    <h5>
                      Getting Customer Risk Assessment Modeling Right for
                      Microfinance Lenders
                    </h5>
                    <p className="small-text">
                      A Good Customer Risk Assessment Model is to keep track of
                      borrower risk during the tenure of the loan, post
                      disbursement by monitoring risk profile change of a
                      borrower periodically.
                    </p>

                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        window.open(
                          window.__BlogUrl__ +
                            "getting_credit_risk_assessment_model_right",
                          "_blank"
                        );
                      }}
                    >
                      Read More
                      <ArrowRight></ArrowRight>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
