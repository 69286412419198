import React, { Component } from "react";
import { Link } from "react-router-dom";
import DropdownSection from "./dropdownSection";

export default class NavItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkActive: false,
      dropdownActive: false,
    };
    this.navItemRef = React.createRef();
    this.dropdownRef = React.createRef();
    this.routeListener = undefined;
  }

  componentDidMount() {
    if (this.props.history) {
      this.routeListener = this.props.history.listen(() => {
        this.onRouteChange();
      });
      this.checkIfCurrentRouteMatches();
    }
  }

  checkIfCurrentRouteMatches() {
    const pathName = this.props.history.location.pathname;

    this.setState({
      linkActive: this.props.item.to === pathName,
    });
  }

  onRouteChange() {
    this.checkIfCurrentRouteMatches();
    this.hideDropdown();
  }

  getLink(item) {
    if (!item.to) {
      return (
        <span
          data-id={item.id}
          key={item.id}
          onMouseOver={(e) => this.onMouseOver(e)}
          onClick={(e) => this.onNavItemClick(e)}
          // onMouseLeave={(e) => this.onMouseLeave(e)}
          className="link"
        >
          {item.title}
          <div className="nav-item-underline"></div>
        </span>
      );
    } else {
      return (
        <Link
          to={item.to}
          data-id={item.id}
          key={item.id}
          className="link"
          onMouseOver={(e) => this.onMouseOver(e)}
          onClick={(e) => this.onNavItemClick(e)}
        >
          {item.title}
          <div className="nav-item-underline"></div>
        </Link>
      );
    }
  }

  render() {
    const item = this.props.item;
    const hasDropdown = item.dropdown !== undefined;
    return (
      <li
        ref={this.navItemRef}
        className={"nav-link " + (this.state.linkActive ? "active " : "")}
      >
        {this.getLink(item)}
        {hasDropdown ? (
          <span className="nav-drop-icon-wrapper">
            <span className="arrow-icon">
              <span></span>
              <span></span>
            </span>
          </span>
        ) : (
          ""
        )}
        <DropdownSection
          ref={this.dropdownRef}
          childComp={item.dropdown}
          onDropdownHiddenCallback={() => this.onDropdownHiddenCallback()}
        />
      </li>
    );
  }

  removeActive() {
    this.navItemRef.current.classList.remove("active");
    if (this.props.item.dropdown) {
      this.hideDropdown();
    }
  }

  hideDropdown() {
    const navItem = this.navItemRef.current;
    if (!this.state.linkActive) {
      navItem.classList.remove("active");
    }
    if (this.props.item.dropdown) {
      const navDropIconWrapper = navItem.querySelector(
        ".nav-drop-icon-wrapper"
      );
      navDropIconWrapper.classList.remove("dropdown-active");
      this.dropdownRef.current.hideDropdown();
    }
  }

  onDropdownHiddenCallback() {
    this.hideDropdown();
    this.props.onDropdownHideOrShow(this.props.item.id, false);
  }

  onDropdownShow() {
    if (!this.props.item.dropdown) return;
    const navItem = this.navItemRef.current;
    navItem.classList.add("active");
    const navDropIconWrapper = navItem.querySelector(".nav-drop-icon-wrapper");
    navDropIconWrapper.classList.add("dropdown-active");
    this.props.onDropdownHideOrShow(this.props.item.id, true);
  }

  onMouseOver(e) {
    e.preventDefault();
    e.stopPropagation();
    this.onDropdownShow();
    this.dropdownRef.current.showDropdown();
  }

  onNavItemClick(e) {
    debugger;
    if (e.currentTarget.nodeName !== "A") {
      this.onMouseOver(e);
    }
  }

  onMouseLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      this.dropdownRef.current.hideDropdown();
    }, 1000);
    // this.dropdownRef.current.hideDropdown();
  }
}
