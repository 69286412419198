/* eslint-disable eqeqeq */
import React, { Component } from "react";
import ActionButtons from "./actionButtons";
import CarouselDots from "./carouselDots";
import ListAccordion from "./listAccordion";

export default class Slideshow extends Component {
  constructor(props) {
    super(props);
    this.listData = Slideshow.getListData(this.props.data);
    this.state = {
      currentIndex: null,
    };
    this.listAccordionRef = React.createRef();
    this.carouselDotsRef = React.createRef();
  }

  componentDidMount() {
    const defaultId = 0;
    this.setState({
      currentIndex: defaultId,
    });
    this.listAccordionRef.current.setToActive(defaultId);
    this.carouselDotsRef.current.setToActive(defaultId);
  }

  static getListData(data) {
    return data.map((item) => {
      const { id, title, DescriptionComp } = item;
      return {
        id,
        title,
        DescriptionComp,
      };
    });
  }

  onAccItemClickCb(id) {
    this.setState({
      currentIndex: id,
    });
    this.carouselDotsRef.current.setToActive(id);
  }

  onActionButtonClick(direction) {
    const currentId = this.state.currentIndex;
    let nextId;
    const dataLength = this.listData.length;
    if (direction === "left") {
      if (currentId == 0) {
        nextId = dataLength - 1;
      } else {
        nextId = currentId - 1;
      }
    }
    if (direction === "right") {
      if (currentId == dataLength - 1) {
        nextId = 0;
      } else {
        nextId = currentId + 1;
      }
    }

    this.setState({
      currentIndex: nextId,
    });
    this.listAccordionRef.current.setToActive(nextId);
    this.carouselDotsRef.current.setToActive(nextId);
  }

  getItemById(id) {
    return this.props.data.find((item) => item.id == id);
  }

  onCarouselDotClick(id) {
    this.setState({
      currentIndex: id,
    });
    this.listAccordionRef.current.setToActive(id);
  }

  render() {
    const currentActiveItem = this.getItemById(this.state.currentIndex);
    let imgSrc;
    if (currentActiveItem) {
      imgSrc = currentActiveItem.imgSrc;
    }

    return (
      <div className="cyn-slideshow">
        <div className="container-fluid">
          <div className="row cyn-slideshow-row justify-content-between g-5">
            <div className="col-lg-5 col-md-6">
              <div className="list-wrapper">
                <ListAccordion
                  data={this.listData}
                  ref={this.listAccordionRef}
                  clickCb={(id) => this.onAccItemClickCb(id)}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 position-relative ">
              <div className="position-sticky mt-5" style={{ top: "80px" }}>
                <div className="mb-4">
                  <ActionButtons
                    className="h-10"
                    clickCb={(direction) => this.onActionButtonClick(direction)}
                  />
                </div>
                <div className="img-wrapper h-75">
                  {imgSrc ? (
                    <img
                      data-id={this.state.currentIndex}
                      alt="cynepia slideshow"
                      src={imgSrc}
                      width="100%"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="text-center col-lg-12">
              <CarouselDots
                ref={this.carouselDotsRef}
                clickCb={(id) => {
                  this.onCarouselDotClick(id);
                }}
                length={this.listData.length}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
