import React, { Component } from "react";
import { Link } from "react-router-dom";
import SocialIcons from "../../../socialIcons/socialIcons";
import AnimatedArrowIconLink from "../../../animatedArrowIconLink/animatedArrowIconLink";

export default class CompanyDropdown extends Component {
  handleItemClick(name) {
    this.setState({ activeItem: name });
  }

  render() {
    return (
      <div
        className="nav-dropdown w-50  position-relative ml-auto "
        style={{ width: "30px" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-5 col-lg-5 bg-white ">
              <div className="p-4 h-100 position-relative ">
                <h4>Who we are ?</h4>
                <div className="list-wrapper">
                  <ul className="list-group list-unstyled">
                    <li className="mb-2">
                      <Link to="/about-us" className="text-transform-uppercase">
                        About Us
                      </Link>
                    </li>
                    <li
                      className="cursor-pointer mb-2"
                      onClick={() => {
                        window.open(window.__CareersUrl__, "_blank");
                      }}
                    >
                      Careers
                    </li>
                    <li className="mb-2">
                      <Link to="/partners">Partners</Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                  </ul>
                </div>
                <div
                  className="position-absolute social-icon-wrapper"
                  style={{ bottom: 20, right: 0 }}
                >
                  <SocialIcons />
                </div>
              </div>
            </div>

            <div className="col-xxl-7 col-lg-7 accent-bg-2">
              <div className="p-4 ">
                <h5 className=" mb-4">Cynepia Technologies</h5>
                <p className=" medium-text">
                  <b>
                    <span className="text-highlight-dark-background">
                      Our Stratergy
                    </span>
                  </b>{" "}
                  is to provide cutting edge software systems to help
                  enterprises organize, connect, make sense of their data, stay
                  connected with their insights, make faster, real-time
                  decisions and ultimately grow your business.
                </p>
                <AnimatedArrowIconLink to="/about-us" content="Read More" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
