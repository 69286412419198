import React, { Component } from "react";
import ContactUsSection from "../../../contactUsSection/contactUsSection";
import { CallMade } from "@material-ui/icons";
export default class DataEngineer extends Component {
	static getList() {
		return [
			"Onboard data from disparate data sources in hours. Cynepia consulting team enables connecting custom bespoke apps/tools within weeks.",
			"Create and maintain optimal data pipeline with Xceed Visual Designer — a no code way to build and manage data pipelines and create high quality data models for ML and Analytics use cases. Be it CRM or HR or a Marketing Model, Our normalized datalake storage ensures you can combine data from any sources effortlessly. ",
			"We have it all covered, Build your data pipeline leveraging more than 40 visual recipes. Stop worrying about deployment, language syntax, scalability & productionizing. Extract value off the raw data in datalake and create high quality data models for your use cases. ",
			"Automate day to day data engineering tasks and accelarate time to value.",
			"Iterate, Manage & Monitor pipeline performance & breakages.",
			"Update/Build our data pipelines faster and publish/share data models using data catalog effortlessly with ML and Business Analysis team.",
		];
	}

	render() {
		return (
			<div className="page" id="dataEngineerRolePage">
				<div className="page-section">
					<div className="page-container container hero-container">
						<div className="row justify-content-center">
							<div className="col-lg-8 text-center">
								<h1>Xceed Analytics for Data Engineers</h1>
							</div>
							<div className="col-lg-12">
								<img
									src="/images/dataEngineerRole.png"
									width="100%"
									alt="data Engineer"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className="page-section">
								<div className="page-container container">
									<h3>Data Engineering</h3>
									{DataEngineer.getList().map((listItem) => {
										return (
											<p className="d-flex align-item-start">
												<span className="icon-wrapper me-2">
													<CallMade></CallMade>
												</span>
												<span>{listItem}</span>
											</p>
										);
									})}
								</div>
							</div>
							<ContactUsSection />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
