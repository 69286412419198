import React, { Component } from "react";
import Item from "./accordionItem";
import "./index.scss";

export default class MultiLevelAccordionNavItem extends Component {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
	}

	selectItems(obj) {
		this.unselectAllLInks();
		Object.keys(obj).forEach((category) => {
			const categoryItems = obj[category];
			categoryItems.forEach((categoryItem) => {
				const el = this.ref.current.querySelector(
					`.link[data-id = '${categoryItem}'][data-category='${category}']`
				);
				if (el) {
					el.classList.add("selected");
				}
			});
		});
	}

	unselectAllLInks() {
		const links = this.ref.current.querySelectorAll(".link");
		links.forEach((el) => el.classList.remove("selected"));
	}

	onItemSelect(id) {
		if (!this.props.clickCb) return;
		if (this.props.multiselect) {
			this.props.clickCb(this.getAllSelectedItems());
		} else {
			this.props.clickCb(id);
		}
	}

	getAllSelectedItems() {
		const obj = {};
		const allSelectedEls = this.ref.current.querySelectorAll(".link.selected");
		debugger;
		allSelectedEls.forEach((el) => {
			const { dataset } = el;
			const { category, id } = dataset;
			if (Object.prototype.hasOwnProperty.call(obj, category)) {
				obj[category].push(id);
			} else {
				obj[category] = [id];
			}
		});
		return obj;
	}

	render() {
		return (
			<div
				className="multi-level-accordion-nav-items 
				container p-0"
				ref={this.ref}
			>
				<ul>
					{this.props.links.map((item, i) => {
						return (
							<Item
								item={item}
								index={i}
								key={i}
								clickCb={(id) => this.onItemSelect(id)}
								multiselect={this.props.multiselect}
							/>
						);
					})}
				</ul>
			</div>
		);
	}
}
