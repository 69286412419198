import React from "react";

function Privacy() {
  return (
    <>
      <div className="page" id="privacypage">
        <div className="page-section">
          <div className="page-container container">
            <h1>Privacy</h1>
            <p>
              This version of Cynepia Technologies Privacy Policy (“Policy”) has
              been in effect since 19th August 2016. Use of this site signifies
              your acceptance to the terms and conditions of this Privacy Policy
              and the Terms of Use.
            </p>
            <p>
              This version of Cynepia Technologies Privacy Policy (“Policy”) has
              been in effect since 19th August 2016. Use of this site signifies
              your acceptance to the terms and conditions of this Privacy Policy
              and the Terms of Use. In this Policy, the words “you” or “your” or
              “User” or any similar words as the context may require reference
              to "the User". In this Policy, “Cynepia Technologies”, “We”, “Us”,
              “Our” or any similar words as the context may require refer to
              “Cynepia Technologies Pvt Limited”.
            </p>
            <p>
              We respect your privacy and we would take all possible measures to
              protect it.
            </p>
            <p>
              We acknowledge the fact that some of the visitors and users of
              this Site may be concerned about the information they provide to
              us, and how we treat that information. We recognize and appreciate
              these concerns. This Privacy Policy, which may be updated from
              time to time, has been developed to address those concerns.
            </p>
            <p>
              This agreement is published in compliance with the relevant
              provisions of various Indian laws including:
            </p>
            <ol>
              <li>The Indian Contract Act, 1872 </li>
              <li>
                The Information Technology Act, 2000, [As amended by Information
                Technology (Amendment) Act 2008];
              </li>
              <li>
                The Information Technology (Reasonable Security Practices and
                Procedures and Sensitive Personal Information) Rules, 2001 (the
                “SPI Rules”) and{" "}
              </li>
              <li>
                The Information Technology (Intermediate Guidelines) Rules,
                2011.
              </li>
            </ol>
            <p>
              This Policy information covers the information, that is collected,
              what we do with it, and what you can do about it. You can use this
              information to make your decisions about your privacy.
            </p>
            <p>
              This Policy relates solely to the information collected online and
              the use practices of such information by our website located at
              www.cynepia.com(the "Site") and also the services provided by the
              Site and the Mobile Application (“Service”) and utilised by you.
              This Policy does not apply to information collected in any other
              way, including offline.
            </p>
            <p>
              If you have any questions or concerns regarding this Privacy
              Policy, please contact: info@cynepia.com
            </p>
            <p>
              This Site may contain links to the other websites maintained
              and/or operated by third parties. This Policy does not reflect the
              privacy practices of those websites. We are not responsible for
              the privacy practices or policies of such third party websites. We
              do not share any of the individual personal information that you
              provide us with the websites to which the Site links, although we
              may share aggregate data with such websites (such as how many
              people use our Site). Please check with those websites to
              determine their privacy policy.
            </p>
            <p>
              To make this notice easy to find, we have made it available on our
              home page.
            </p>
            <p>
              <strong>What information is collected?</strong>
            </p>
            <p>
              User information is collected in three ways: (i) directly from the
              user (for example, registering an account, or signing up for any
              other services), (ii) from our web server logs, and (iii) through
              cookies.
            </p>
            <p>The information we collect may be of the following kinds.</p>
            <p>
              (a)Personally Identifiable Information / Personal Information:
            </p>
            <ul>
              <li>
                Personally Identifiable Information/ Personal Information for
                the purposes of this policy shall mean “Personal information” as
                defined in Rule 2(i) of Information Technology (Reasonable
                security practices and procedures and sensitive personal data or
                information) Rules, 2011 including any information that relates
                to a natural person, which, either directly or indirectly, in
                combination with other information available or likely to be
                available with a body corporate, is capable of identifying such
                person and may be understood as information such as, name,
                address, email address, phone number, country, state, zipcode
                etc.
              </li>
            </ul>
            <p>(b)Sensitive Personal Data or Information</p>
            <ul>
              <li>
                Sensitive Personal Data or Information for the purposes of this
                policy shall mean “Sensitive Personal Data or Information” as
                defined in Rule 3 of Information Technology (Reasonable security
                practices and procedures and sensitive personal data or
                information) Rules, 2011 including such personal information
                which consists of information relating to;— (i)password;
                (ii)financial information such as Bank account or credit card or
                debit card or other payment instrument details; (iii)sexual
                orientation; (iv)any detail relating to the above clauses for
                providing service; and
              </li>
            </ul>
            <p>
              Any information that is freely available or accessible in public
              domain or furnished under the Right to Information Act, 2005 or
              any other law for the time being in force shall not be regarded as
              sensitive personal data or information for the purposes of these
              rules.
            </p>
            <p>(c) Non- Personally Identifiable/General User Information</p>
            <p>
              This Site does not automatically collect personal information from
              each visitor to the Site. It does recognize the home server of
              visitors, but not e-mail addresses. For example, it collects
              information about the Internet Service Provider, which our
              visitors use, but not the names, addresses or other information
              about our visitors that would allow us to identify a particular
              visitor to our Site. This information so collected is used only
              for internal purposes by our technical support staff.
            </p>
            <p>
              The Site tracks certain information about the visits to the Site.
              For example, statistics that show the numbers and frequency of
              visitors to the Site and its individual pages and time spent by
              each visitor on each page is collected and compiled. These
              aggregated statistics are generally used internally to improve the
              Site and for product development and marketing purposes. Those
              aggregated statistics may also be provided to advertisers and
              other third parties, but again, the statistics contain no personal
              information and cannot be used to gather such information. We may
              collect non-personally identifiable information from any content
              generated by you or your use of such user generated content. You
              will always be notified before any personal information from you
              is collected by us. Only such personal information is collected,
              which you choose to provide and such collection of personal
              information will be with your consent.
            </p>
            <strong>Information collected before formal registration</strong>
            <p>
              You, as a user, have total control over the privacy of your
              information. Your personal information will never be sold,
              exchanged or disclosed to third party by us for marketing purposes
              or for our personal benefits. We collect your personal
              information, if you decide to provide us the same in order to use
              our Services. We do not collect information about our visitors
              from email databases, private/ public organization or bodies.
            </p>
            <p>
              We may use your information to contact you via email or telephone,
              typically to get feedback, send newsletter, updates, to inform you
              about new products/features or provide support to your issues. If
              you do not want us to contact you via email, you can “Unsubscribe”
              at any time with one click.
            </p>
            <strong>Basic Information collected for/after registration</strong>
            <p>
              To enable us to help serve you better, and provide access to
              certain services we offer we would require your personal
              information, such as, Name, address, email address, phone number,
              country, state, zip code, etc., and the other relevant information
              may be collected. Some fields are mandatory to be filled while the
              others may be optional. We typically ask for your Name, e-mail
              address, phone number, address, Log In Name and Password, as well
              as, other similar personal information that is needed to register
              or subscribe you to services or offers. On certain circumstances,
              we may ask for additional information to enable us to provide you
              with access to and use of certain information, materials and
              services. Additionally, the information related to the type of
              device you use, operating system version to provide your device a
              unique ID shall also be collected.
            </p>
            <strong>Registration and use of information</strong>
            <p>
              This Site intends to act as a platform for users to use this Site
              and Services. In specific circumstances, we may use the personal
              information provided by You, to provide you the relevant services.
            </p>
            <p>
              We take reasonable precaution to ensure that your personal
              information is shared with service providers only on an “as
              needed” basis and only such personal information is shared, which
              is essential for the provision of the service opted for.
            </p>
            <p>
              We may share some or all of your Personal Information with our
              subsidiaries and other affiliates, without requesting your
              specific consent to such internal sharing. Your registration with
              us authorizes us to use your personal information for the
              following general purposes, such as:
            </p>
            <ol>
              <li>
                To ensure the right credentials or to initiate background checks
                on the details provided in order to provide continued access.
              </li>
              <li>
                To enforce safe interactions and enforce our policies and or
                reporting structures;
              </li>
              <li>
                to customize user’s experience, measure interest in our
                services, and inform user’s about services and updates (your
                information aids us to better respond to your individual needs);
              </li>
              <li>
                to communicate marketing and create custom promotional offers to
                you;
              </li>
              <li>
                to allow your access to other service related features added
                from time to time; and
              </li>
              <li>to resolve disputes and troubleshoot problems;</li>
              <li>to process transactions.</li>
              <li>
                The email address you provide for order processing, may be used
                to send you information and updates pertaining to your needs, in
                addition to receiving occasional company news, updates, related
                product or service information, etc.,
              </li>
            </ol>
            <strong>ACCESS TO INFORMATION COLLECTED</strong>
            <p>
              AT ANY POINT OF TIME, WE DO NOT RENT, SELL, OR SHARE PERSONAL
              INFORMATION ABOUT YOU WITH THIRD PARTIES OTHER THAN AS DISCLOSED
              WITHIN THIS PRIVACY POLICY.
            </p>
            <p>
              We collect and store the personal information provided by you.
            </p>
            <p>
              We may provide your personal information to companies that provide
              services to help us with our business activities such as
              processing your payment (for e.g. PayPal or any other RBI approved
              online banking partner). These companies are authorized to use
              your personal information only as necessary to provide these
              services to us. Your credit card information is never stored in
              our system as it is processed by our payment gateway which uses
              Secure Encryption Technology (SSL), CCAvenue, PayPal and/or Google
              Checkout etc.
            </p>
            <p>
              Your personal information may be shared with third party (ies),
              apart from service providers, under exceptional circumstances,
              such as:
            </p>
            <ol>
              <li>
                if required by law for a legal proceeding; in good faith only if
                disclosure is necessary to protect our rights, protect your
                safety or the safety of others, investigate fraud, or respond to
                a government request; in case of a merger, acquisition, or sale
                of all or a portion of our assets.
              </li>
            </ol>
            <p>
              However, in case such disclosure is to be made, you will be
              notified via email and/or a prominent notice on our Site of any
              change in ownership or uses of your personal information, as well
              as any choices you may have regarding your personal information.
            </p>
            <strong>Editing the Personal Information</strong>
            <p>
              Personal information shared by you may be edited, amended,
              removed, updated by you anytime by making the change on our member
              information page or by emailing us at info@cynepia.com. We will
              respond to your request to access within a reasonable time.
              However, we may retain your information as long as your account is
              active or as needed to provide you services. If you wish to cancel
              your account or request that we no longer use your information to
              provide you services, please contact us at the email address
              listed above. We will retain and use your information as necessary
              to comply with our legal obligations, resolve disputes, and
              enforce our agreements.
            </p>
            <strong>Opt-Out Policy</strong>
            <p>
              We offer our visitors and customers a means to choose how we may
              use information provided by them. If, at any time after
              registering for ordering the Service or to avail the services
              offered by the Site or for information, you change your mind about
              receiving communications such as newsletters or marketing emails
              from us, send us a request specifying your new choice. Simply send
              your request to info@cynepia.com.
            </p>
            <strong>Cookies</strong>
            <p>
              In order to enhance your online experience and track the
              performance of the Site, our Site uses cookies. Our cookies help
              us improve your online experience, allow you to personalize your
              pages, enable us to customize our offerings. Cookies do not tell
              us who you are and contains non-personal information.
            </p>
            <p>
              Cookies also help us determine how many people visit our sites,
              web pages they use and how long they stay there. This information
              helps us evaluate our web features are successes and need
              improvement. If you wish, you can disable the use of cookies
              through your Internet browser. For administrative purposes, we
              also collect IP addresses, which are the automatic numbers
              assigned to your computer when you surf the web. Certain Internet
              Service Providers may assign the same IP address to many users.
              Your IP address and cookies are not connected to any personally
              identifiable or on-line contact information like a name and
              address unless you order or register at our site; however, if you
              register, all information we have about you will be associated
              with your customer file.
            </p>
            <p>
              You have the ability to accept or decline cookies. Most Web
              browsers automatically accept cookies, but you can usually modify
              your browser setting to decline cookies. If you choose to decline
              cookies, you may not be able to fully experience the interactive
              features of this or other websites you visit.
            </p>
            <strong>Clear Gifs (Web Beacons/Web Bugs)</strong>
            <p>
              GIF (Graphics Interchange Format) is a type of image format
              employed by websites. Our third party advertising partners may
              employ a software technology called clear gifs (a.k.a. Web
              Beacons/Web Bugs), that helps us to better manage content on our
              Site by informing us effectiveness of the content. Clear gifs are
              tiny graphics with a unique identifier, similar in function to
              cookies, and are used to track the online movements of Web users.
              In contrast to cookies, which are stored on a user’s computer hard
              drive, clear gifs are embedded invisibly on Web pages and are
              about the size of the period at the end of this sentence. We do
              not tie the information gathered by clear gifs to our customers’
              personally identifiable information.
            </p>
            <strong>How is my personal information protected?</strong>
            <p>
              The information that You provide, subject to disclosure in
              accordance with Our Privacy Policy and Terms of Use, shall be
              maintained in a safe and secure manner. Our databases and
              information are stored on secure servers with appropriate
              firewalls. We have implemented certain appropriate security
              measures to protect your personal information from accidental loss
              and from unauthorized access, use, or disclosure. We store the
              information about you in a data center with restricted access and
              appropriate monitoring. We use intrusion detection and virus
              protection software.
            </p>
            <p>
              Despite these measures, we cannot guarantee that unauthorized
              persons will always be unable to defeat our security measures.
              Given the nature of internet transactions, we do not take any
              responsibility for the transmission of information including User
              Information to the Website. Any transmission of User Information
              on the internet is done at Your risk. We do not take any
              responsibility for You or any third party circumventing the
              privacy settings or security measures contained on the Website.
            </p>
            <p>
              While we will use all reasonable efforts to ensure that Your User
              Information and other information submitted by You is safe and
              secure, we offer no representation, warranties or other assurances
              that the security measures are adequate, safe, fool proof or
              impenetrable.
            </p>
            <p>
              As a User of the Website, You have the responsibility to ensure
              data security. You should use the Website in a responsible manner.
              Do not share Your username or password with any person. You are
              solely responsible for all acts done under the username You are
              registered under.
            </p>
            <strong>Information Practices</strong>
            <p>
              Every possible effort has been taken to ensure the accuracy of the
              information on the Site. The content is naturally subject to
              change with time. We are not responsible for any damages arising
              out of the use of the information held on this server and the
              Site.
            </p>
            <strong>Children’s Privacy</strong>
            <p>
              We believe that it is especially important to protect children’s
              privacy online. For that reason, we do not knowingly collect or
              maintain personal information through the website from persons
              younger than 13 years of age, and no part of our website is
              directed to persons younger than 13. If you are younger than 13
              years of age, then please do not use or access the website at any
              time or in any manner. If we learn that personally identifiable
              information of persons younger than 13 years of age has been
              collected through the website without verified parental consent,
              then we will take the appropriate steps to delete this
              information.
            </p>
            <strong>Changes to this Privacy Policy</strong>
            <p>
              This Policy terms may change from time to time and we reserve the
              right to change the terms of this Policy. All registered users
              will be notified via email whenever; any change is made in the
              Policy.
            </p>
            <stong>Intellectual Property information</stong>
            <p>
              The content of the Site is original piece of work created by Us
              and we hold copyright and all other intellectual property rights
              on content, images, videos, look and feel of the Site, unless
              otherwise indicated.
            </p>
            <strong>Questions regarding Privacy Policy</strong>
            <p>
              If you have any questions regarding this Policy, please write to
              us at info@cynepia.com with PRIVACY POLICY ISSUES in subject line
              and we would respond immediately within 24 hours, without undue
              delay.
            </p>
            <p>
              Any feedback you provide at this Site shall be deemed to be
              non-confidential. We reserve the right to freely use such
              information on an unrestricted basis.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
