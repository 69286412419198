import React, { Component } from "react";

export default class EndToEndAnalyticsMicrofinance extends Component {
  render() {
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="page-section">
              <div className="page-container">
                <h3>The Challenge</h3>
                <p>
                  As data grew rapidly from a few hundred thousand to a few
                  million records per dataset, the regular desktop BI solution
                  along with Excel began to pose a problem in itself.
                </p>
                <p>
                  Further complicating the issue was the lack of version
                  management and domain modeling resulting in large number of
                  ad-hoc queries from the business and executive teams.
                </p>
                <p>
                  Data resided in various databases/repositories often known
                  only to a closed group of Business Analyst.
                </p>
                <p>
                  Business User Expectation on quality and speed of insights and
                  number of users asking such question grew rapidly.
                  Just-in-time Adhoc analysis became a non-starter.
                </p>
              </div>
            </div>
            <div className="page-section">
              <div className="page-container">
                <h3 className="mb-4">The Solution</h3>
                <h5 className="">
                  Unified Data Intelligence Using Xceed Analytics
                </h5>
                <p>
                  Over a period of very short time, all the offline/online data
                  sources were onboarded into Xceed Analytics and 30 plus data
                  workflows including many of the data cleanup/transformation
                  workflows were onboarded on Xceed Analytics, Domain Models
                  were built rapidly using Xceed SDK. Further, Monthly and Daily
                  Dashboards for various functional groups including HR, Finance
                  and Business Operations were created.
                </p>
              </div>
            </div>
            <div className="page-section">
              <div className="page-container">
                <h3>Benefits & Next Steps</h3>
                <ol>
                  <li>
                    Unified interface enabled various teams from Business
                    Executives, Business Operations, HR, Sales Operations
                    collaborate seamlessly both with data and with other teams.
                  </li>
                  <li>
                    End-to-end automation ensured that all the user dashboards
                    were seamlessly updated on availability of newer data.
                  </li>
                  <li>
                    Availability of versioned data centrally ensured access to
                    data was possible across teams of data users and
                    collaborators and also time trail to fetch a past data.
                  </li>
                  <li>
                    Domain Models ensured that a variety of queries were
                    answered instantly without needing to prepare data
                    instantly.
                  </li>
                  <li>
                    Integrated Self-service Dashboard further helped perform
                    ad-hoc analysis, if further modeling is required.
                  </li>
                  <li>
                    In Months to come, Financial Institution also plans to do
                    predictive modeling using Xceed Analytics.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
