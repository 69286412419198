import React, { Component } from "react";

export default class KnowYourBranchNetwork extends Component {
  render() {
    return (
      <div className="page-section">
        <div className="page-container container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="page-section">
                <div className="page-container container-flui">
                  <h2>Challenge</h2>
                  <p>
                    Retail Banks/Financial Institutions often have large branch network.
                    These Bank outlets provide a variety of products/services to the end consumer. 
                    Banking services which are regarded as retail include various products such as saving, 
                    fixed deposits and transaction/current accounts, housing loans, mortages, personal loans,
                    gold loans, credit/debit cards etc. Customers often engage with bank/fi
                    through a variety of channels including offline channels such as Branch itself,
                    Sales executives,Partner channels and various online channels such as
                    website, mobile application, online partner channels etc.
                  </p>
                  <p>
                     Understanding Individual Branch Performance can be daunting for a large branch footprint.
                     Branch Data Models have the potential to provide a 360 degree understanding of Branch Operations
                     in a retail banking scenario. Incorporating local knowledge provides a complete view of branch’s
                     customer base, portfolio churn, credit disbursement & delinquency trends, loan book insourcing trends,
                     savings/checking insourcing trends, staff/hr turnover trends,
                     branch P&L trends, localized events, competitve scenario and much more.
                  </p>
                  <p>
                      Network analysis/Clustering can further provides sophisticated summary reporting across branches and regions,
                      enabling management to grade branch performance at a regional/organisational levels. 
                      Identify performers and encourage those through better targets and incentives and 
                      corrective actions for branches where performance doesn’t meet the goal.
                  </p>
                </div>
              </div>
              <div className="page-section">
                <div className="page-container">
                  <h2>Solution</h2>
                  <h5>Deeper Branch Level Insights using Branch Data Model</h5>
                  <p>
                    Create detailed branch level Data Model by bringing in data from various source applications.
                    Branch data model includes extensive set of Branch Level KPIs including MoM, QoQ, YoY metrics 
                    giving insights into various branch processes including deposits, loan portfolio, disbursements, 
                    collections, delinquencies, HR, P&L trends, product wise trends, 
                    comparitive analysis viz-a-viz region, cluster or organisation.
                  </p>

                  <p>
                    Uncover the relationships between branch, its customers and products. 
                    Understand key risks, drivers for the branch, compare with other branches across 
                    various dimensions within the organisation, compare with localized competitors.
                  </p>

                  <p>
                    Visualise branch data and insight in real-time whilst creating smart segments-banishing the need for endless iterations
                    and rebuilds using integrated self service Business Intelligence Platform
                  </p>

                  <p>
                    Take advantage of advanced data science techniques, machine learning and AI with our easy to use visual, no-code platform. 
                    Build Machine Models to Optimize Portfolio Recommendations, use forecasting techniques to forecast future values 
                    for various portfolio and risk related KPIs.
                  </p>
                    
                  <p>
                    Include Branch level localized attributes and further optimize Credit Assessment Model for new applications.
                  </p>
             
                  <p>
                    Include Branch level localized attributes and optimize/streamline staff churn and other HR processes.
                  </p>

                  <h5>Segment Branches Better</h5>
                  <p>
                      Network analysis/Clustering can further provides sophisticated summary reporting across branches and regions,
                      enabling management to grade branch performance at a regional/organisational levels.
                  </p>

                  <p>
                      Identify performers and encourage those through better targets and incentives.
                      Take corrective actions for branches where performance doesn’t meet the goal.
                  </p>

                  <p>
                      Create specific audiences from branch segments using our decision engine, then deliver unique, highly personalized product offerings.
                  </p>

                  <p>
                      Streamline and Understand Branch HR Processes better including staff churn, staff incentive etc.
                  </p>

                  <h5>Benefits/Business Value</h5>
                  <p>
                      A focused and well managed branch can help retail banks/financial institutions create enormous benefits.
                      A few of them are listed below:
                  </p>
                  <p>
                      1. Improve Customer relationships by proactively addressing customer specific issues.
                  </p>
                  <p>
                      2. Empower the sales executive to acheive more by proactive recommendations on next customer needs/propensity & optimizing cross-sell/upsell process.
                  </p>
                  <p>
                      3. Streamlining Branch Sales planning process, by contextualizing growth target setting through better understanding of localized oppurtunity
                  </p>
                  <p>
                      4. Reduce staff turnover through streamlining sales incentive and overall employee expectations
                  </p>
                  <p>
                      5. Optimizing Branch portfolio risk through improved localized risk assessment.
                  </p>
                  <p>
                      6. Gain consistent localized market intelligence
                  </p>
                </div>
              </div>
              <div className="page-section">
                <div className="page-container">
                  <h2>Conclusion</h2>
                  <p>
                     In summary, a unified data and AI platform does not just acts as a central knowledge repository of all your branch related data assets and make it easily for every one who needs access to find, search and discover data assets.
                  </p>
                  <p>
                     Integrated BI and Automated Machine Learning tools further help get deeper insights into whats happening, what’s the future like as well as build prescriptive models helping improve planning process.
                  </p>
                  <p>
                      It further eliminates the manual processes by building workflows for various branch network related use cases.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
