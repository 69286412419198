import React, { Component } from "react";
import "./dataCatalogue.scss";
import ContactUsSection from "../../../contactUsSection/contactUsSection";
import CtaButtons from "../../../ctaButtons/ctaButtons";
import ListComponent from "../../../listComponent/listComponent";

export default class DataCatalogue extends Component {
  render() {
    return (
      <div className="page" id="dataCataloguePage">
        <div className="page-section">
          <div className="page-container container hero-container">
            <div className="row align-items-center g-3">
              <div className="col-lg-12 col-md-12 text-center mb-4">
                <h1 className="mb-3">Find, Govern & Collaborate</h1>
                <p className="fs-5 mb-4">
                  Effectively access & govern information about your data all
                  from a single view.
                </p>
                <CtaButtons center={true} />
              </div>
              <div className="col-lg-12  col-md-12 h-100 right-section">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataCatalogueBanner.svg"
                    width="80%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row">
              <div>
                <h2>
                  Save time searching, quickly find any data you are searching
                  for
                </h2>
                <p>
                  Xceed Data Catalog helps enterprise users find the data they
                  need quickly. It further helps data owners govern data
                  effectively by identify, track and govern sensitive
                  information by ensuring that only the data that is required is
                  shared. Be it raw datasets ingested in your datalake/data
                  warehouse, or the feature data from various data workflows,
                  discover all your data from a single view. Build dashboards
                  for BI, New ML models or discover metadata, data revisions or
                  download data for further analysis, we have it all covered.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-center g-5">
              <div className="col-xxl-5 col-lg-5">
                <h6 className="pre-heading-highlight fw-bold">
                  Better manage your enterprise data aseets
                </h6>
                <h3>One place to find all your data assets</h3>
                <ListComponent
                  data={[
                    "All the data available in the data lake is available instantly to whoever needs it.",
                    "No hassle of maintaining dataset revisions. Find historical versions of the dataset whenever you need.",
                    "Single source of truth across all use cases.",
                    "Query your data, right from the catalogue.",
                  ]}
                />
              </div>
              <div className="col-xxl-7 col-lg-7">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataCatalogueVisual1.svg"
                    width="100%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container ">
            <div className="row g-5">
              <div className="col-xxl-7 col-lg-7">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataCatalogueVisual2.svg"
                    width="90%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
              <div className="col-xxl-5 col-lg-5">
                <h6 className="pre-heading-highlight fw-bold">
                  Improve ROI on Data Quality
                </h6>
                <h3>Stay on top of schema changes</h3>
                <ListComponent
                  data={[
                    "Stay on top of your data quality goals using quick data snapshots and schema view.",
                    "Automated Data Profile helps you keep track of shape and quality of important data fields.",
                    "Data Quality Alerts help you be notified if the shape of any of the important field changes with data refresh.",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row g-5">
              <div className="col-xxl-5 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                  Define, Manage and Share with Confidence
                </h6>
                <h3>Stay on top of data quality issues</h3>
                <ListComponent
                  data={[
                    "Reduce Data downtime with our integrated approach to monitoring data product quality",
                    "Track and Manage failures over time. Spot datasets and columns with reliability issues in real-time and take effective action",
                    "Ensure that all stackholders are informed on data quality issues instantly. Never loose trust.",
                    "Integrated into the Unified Data Platform, provides 360 view of all the dataset operations",
                  ]}
                />
              </div>
              <div className="col-xxl-7 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataCatalogueVisual4.svg"
                    width="80%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section">
          <div className="page-container container">
            <div className="row g-5">
              <div className="col-xxl-7 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataCatalogueVisual3.svg"
                    width="80%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>

              <div className="col-xxl-5 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                  Collaborate across all data users
                </h6>
                <h3>Govern, Share and Collaborate</h3>
                <ListComponent
                  data={[
                    "Share your data assets across all the data teams who need access to your model instantly.",
                    "Granular access control & governance with dataset access control and row-level filters within each dataset ensures that users see only the records that they are entitled to.",
                    "Notify users on data refreshes instantly.",
                    "Add a custom data source within days.",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <h3 className="mb-5">Technical Capabilties</h3>
            <div className="row g-3 align-items-strech justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/singleSourceOfTruth.svg"
                        alt="cynepia data connector"
                        width={75}
                      />
                    </div>
                    <h5 className="title">Single Source of Truth</h5>
                    <div className="description">
                      All the data available in the data lake from one single
                      view.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/previewMetadata.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Quick Data Preview</h5>
                    <div className="description">
                      See quick preview of data and all the associated metatada.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/sqlIcon.svg"
                        alt="cynepia historical snapshot"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Write SQL Queries</h5>
                    <div className="description">
                      Write queries and explore data directly on data lake.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/peopleGroup.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Collaborate & Govern</h5>
                    <div className="description">
                      Share & Collaborate with other data teams within the
                      organisation. Approval workflows to ensure, data is
                      reviewed before publishing to wider audience.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/badge.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Ensure Data Quality</h5>
                    <div className="description">
                      Quickly spot data quality errors and take actions to fix
                      them. Approval workflow ensures peer review.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/securityCheck.svg"
                        alt="cynepia data connector"
                        width={40}
                      />
                    </div>
                    <h5 className="title">Enterprise Grade Security</h5>
                    <div className="description">
                      Granular Governance ensures everyone has access to what is
                      required for their specific role. Row level filters
                      further enhance who sees what.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/pipeline.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Automate Data Pipeline Jobs</h5>
                    <div className="description">
                      Run Periodically or Trigger Datasource refresh Job on all
                      remote data connectors. Alert data owners for all refresh.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/versionModels.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Versioned Data Models</h5>
                    <div className="description">
                      Track every revision from the catalog revisions page.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/debug.svg"
                        alt="cynepia data connector"
                        width={60}
                      />
                    </div>
                    <h5 className="title">Troubleshoot & Debug</h5>
                    <div className="description">
                      Data profile and quality alerts can help troubleshoot the
                      data owners about potential issues.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactUsSection />
      </div>
    );
  }
}
