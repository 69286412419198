import React, { Component } from "react";
import ListComponent from "../../../listComponent/listComponent";

export default class SpeedUpReportingDashboards extends Component {
  render() {
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="page-section">
              <div className="page-container">
                <h3>The Challenge</h3>
                <p>
                  Keeping BI dashboards clean, fast and accurate is often a
                  challenge. Switching between data warehouses, data pipelines
                  and BI dashboards to ensure accuracy of enrichments and
                  datasets can be cumbersome and time consuming.
                </p>
                <p>
                  When you use Xceed Workflows & Xceed Data Catalog to create
                  reporting tables, you guarantee that all of your key
                  tables/metrics and workflows are accessible and will match.
                  Integrated BI Dashboard further ensures that the dashboards
                  are updated automatically as new versions of underlying
                  datasets are available.
                </p>
              </div>
            </div>
            <div className="page-section">
              <div className="page-container">
                <h3>Solution</h3>
                <ListComponent
                  data={[
                    "Building dashboards is simple, quick and easy when you use Xceed Dashboards.",
                    "All reporting uses a single source of truth and traceable dataset.",
                    "All data model updates are done in one place.",
                    "All the enrichment is kept in Data Workflows and not in BI tool.",
                    "Switching between Xceed Workflow, Xceed Catalog and Xceed Dashboard as simple as clicking a button.",
                  ]}
                />
              </div>
            </div>
            <div className="page-section">
              <div className="page-container">
                <h3>Summary</h3>
                <p>
                  In summary, a unified data cataog, data workflows and bi
                  dashboards don’t just acts as a central knowledge repository
                  of all your data assets and make it easily for every one who
                  needs access to find, search and discover data assets. It
                  makes the whole process of updating BI Dashboards far more
                  efficient, debug-able and trust-worthy.
                </p>
                <p>
                  Materialized tables/dataset ensures no transformations need to
                  be done while building the dashboard ensuring faster response
                  time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
