import React, { Component } from "react";
export default class ValueProposition extends Component {
  render() {
    return (
      <div className="page-section">
        <div className="page-container container accent-bg-2 p-5">
          <div className="row align-items-center gap-3">
            <div className="col-md-12">
              <h6 className="pre-heading-highlight fw-bold">
                Intelligent Data Platform
              </h6>
              <h3>All-in-One Low Code, Intelligent Data Platform</h3>
              <p>
                Enterprises are loosing ROI on their data and AI use-cases by
                trying to integrate and manage multiple micro applications and
                point solutions . A lot more time is spent trying to put the
                fragmented solutions/applications together than accelerating
                value from their data and AI use-cases. It is now obstructing
                transformational benefits it can otherwise acheive by leveraging
                generative AI.
              </p>
              <p>
                <span className="text-primary">Xceed Analytics </span> is an{" "}
                <span className="text-primary">Intelligent data platform </span>{" "}
                that brings together a unique set of capabilities to accelerate
                your data and AI use cases.{" "}
              </p>
            </div>
            <div className="col-md-12">
              <div
                className="image-wrapper 
									d-flex align-items-center justify-content-center
							"
              >
                <img
                  src="images/cynepia_integrated_tool.svg"
                  width="100%"
                  alt="cynepia_integrated_tool"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
