import React from "react";
import { CheckCircleOutline } from "@material-ui/icons";
import "./listComponent.scss";

export default function ListComponent(props) {
  return (
    <div className="list-container text-muted">
      {props.data.map((item, i) => {
        return (
          <p className="d-flex align-item-start fs-6" key={i}>
            <span className="icon-wrapper me-2">
              <CheckCircleOutline />
            </span>
            <span>{item}</span>
          </p>
        );
      })}
    </div>
  );
}
