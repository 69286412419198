window.__DocumentationUrl__ = "https://docs.cynepia.com/";
window.__BlogUrl__ = "https://docs.cynepia.com/blog/";
window.__CareersUrl__ = "https://angel.co/company/cynepia-technologies";

window.__BackendUrl__ = "https://api.cynepia.com/";

window.__SubscribeNewsletter__ = window.__BackendUrl__ + "subscribe-newsletter";
window.__CreateContact__ = window.__BackendUrl__ + "get-started";

window.__registerURL__ = `https://account1.cloud.xceedanalytics.net/register`;
