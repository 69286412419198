import React from "react";
import "./inputWithButton.scss";

export default function InputWithButton(props) {
  return (
    <div className="inputWithButton w-100">
      <input
        className="h-100 form-control "
        placeholder={props.inputPlaceholder}
        type={props.inputType}
        required={props.required}
        name={props.inputName}
      />
      <button type={props.buttonType} className="button primary">
        Subscribe
      </button>
    </div>
  );
}
