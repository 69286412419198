import React, { Component } from "react";

export default class CollaborationWorkspace extends Component {
  render() {
    return (
      <div className="page-section">
        <div className="page-container container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="page-section">
                <div className="page-container">
                  <h2>Challenge</h2>
                  <p>
                    Data engineering team needed to serve the workflow output
                    for downstream use cases including Business Intelligence
                    Dashboards and Machine Learning use cases. With the number
                    of use cases growing across various domains including
                    customer 360, branch 360, employee 360 and variety of
                    transformations workflows which would impact the needs of
                    various data analyst from Operations to HR to Finance, Data
                    Science team and even business users, it was cumbersome and
                    time consuming to notify changes and updates and make data
                    available.
                  </p>
                  <p>
                    These teams needed a more efficient way to discover data
                    updates & query datasets or even auto update dashboards as
                    required and models as may be needed. The problem becomes
                    particularly complex and challenging, in a high growth
                    company with new data becoming available at a rapid
                    velocity. Number of such datasets and users grew to hundreds
                    making it highly inefficient for a data engineer to share
                    updates.
                  </p>
                </div>
              </div>
              <div className="page-section">
                <div className="page-container">
                  <h2>Solution</h2>
                  <h5>Home for all your data assets</h5>
                  <p>
                    Xceed Workflows provids a way to publish the data directly
                    to the Data Catalog and then let the users with permissions
                    access those data. It further notifies users with the
                    updates made to the data catalog. Once a new version of a
                    data is available, data consumers are instantly notified of
                    the same making it efficient to collaborate.
                  </p>
                  <p>
                    Downstream BI dashboards are updated seamlessly and can be
                    reviewed and reconciled for trust worthiness by the data
                    analysts quickly.
                  </p>
                  <h5>Data Preview & Column level descriptive statistics</h5>
                  <p>
                    Data Analysts and users can further explore new data/fields
                    added quickly from within the data catalog, preview the data
                    table, review the column level statistics and even download
                    and further slice and dice using the BI Dashboard or Query
                    Interface.
                  </p>
                  <h5>Collaborate right from within the space </h5>
                  <p>
                    The ability to discuss datasets right from within the Xceed
                    Data Catalog helps further desiminate information and
                    collaborate aross data analyst, data engineering and data
                    science teams. Historical conversations are persisted
                    ensuring that the knowledge is preserved for future uses.
                  </p>
                </div>
              </div>
              <div className="page-section">
                <div className="page-container">
                  <h2>Summary</h2>
                  <p>
                    In summary, an integrated data catalog with the Xceed Data
                    workflow not just acts as a central knowledge repository of
                    all your data assets and make it easily for every one who
                    needs access to find, search and discover data assets.
                  </p>
                  <p>
                    It eliminates the manual process by providing a workflow
                    integrated data catalog ensuring, you get rid of manual
                    process and thereby don’t create technical debt by having to
                    integrate disparate data apps with the data catalog.
                  </p>
                  <p>
                    Collaboration from within the same workspace further helps
                    data teams engage efficiently with other users as well as
                    data itself.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
