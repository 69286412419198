import React, { Component } from "react";
import "./dataConnector.scss";
import ContactUsSection from "../../../contactUsSection/contactUsSection";
import ListComponent from "../../../listComponent/listComponent";
import CtaButtons from "../../../ctaButtons/ctaButtons";

export default class DataConnector extends Component {
  render() {
    return (
      <div className="page" id="dataConnectorPage">
        <div className="page-section">
          <div className="page-container container hero-container">
            <div className="row align-items-center g-5">
              <div className="col-lg-5 col-md-12">
                <h2>Connect all your Data Sources in minutes</h2>
                <p className="mb-5">
                  Wide range of plug-n-play connectors to help you move data
                  into the datalake rapidly
                </p>
                <CtaButtons />
              </div>
              <div className="col-lg-7 col-md-12 h-100 right-section">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataConnectorsBanner.svg"
                    width="80%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row">
              <div>
                <h2>
                  Don’t Leave any data behind, connect all your datasources
                </h2>
                <p>
                  Xceed Data Connections provides an easy way to connect to all
                  your databases/data sources. Xceed Analytics no-code data
                  connectors helps connect data from bespoke business
                  applications including CRM Systems, HR Systems and various
                  Databases and consolidates all your data in a centralized
                  datalake. It further normalizes the data in a common storage
                  format across wide range of structured and semi-structured
                  data sources. Automate periodic pulls of data, extraction of
                  metadata from these sources and auto-updates Xceed Data
                  Catalogue seamlessly with the new arrival, update/notifies all
                  the downstream data workflows/users providing seemless
                  end-to-end automation experience. Our Custom Solutions team
                  can help onboard long-tail of bespoke business applications
                  rapidly ensuring no data is left behind.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container accent-bg-2 p-5">
            <div className="row align-items-center g-5">
              <div className="col-xxl-5 col-lg-5">
                <h6 className="pre-heading-highlight fw-bold">
                  Bring all your datasources together in hours
                </h6>
                <h3>Connect all your datasources</h3>
                <ListComponent
                  data={[
                    " Consolidate your data into a centralized data lake seamlessly.",
                    "Wide range of connectors to over 25+ data sources including local files, cloud storages such as Amazon S3, SQL databases such as MS SQL, Oracle, NoSQL Databases such as ElasticSearch as well as API sources such as Twitter.",
                    "Hassle-free intregration without having to understand various communication protocols and data formats.",
                  ]}
                ></ListComponent>
              </div>
              <div className="col-xxl-7 col-lg-7">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataConnectorsVisual1.svg"
                    width="80%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row g-5">
              <div className="col-xxl-7 col-lg-7">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataConnectorsVisual3.svg"
                    width="80%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
              <div className="col-xxl-5 col-lg-5">
                <h6 className="pre-heading-highlight fw-bold">
                  Pre-empt breakages due to schema changes
                </h6>
                <h3>Stay on top Schema Changes</h3>
                <ListComponent
                  data={[
                    "Source Data Systems are dynamic and changes can be fast paced and often unintentional breaking the automation.",
                    "Get notified of changes in schema and formats in source systems on refresh, Take actions to minimize downtime of downstream models and reports.",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container accent-bg-2 p-5">
            <div className="row g-5">
              <div className="col-xxl-5 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                  Improve ROI on Data Integration
                </h6>
                <h3>Fully Automated, Extensible, Auditable</h3>
                <ListComponent
                  data={[
                    "All your data is replicated and versioned in datalake of your choice ensuring auditability over time.",
                    "Select only tables/sources that you want to replicate.",
                    "Automated data refresh ensures better ROI on your resource cost.",
                    "Custom Solutions to onboard long-tail of bespoke business applications within days.",
                  ]}
                />
              </div>
              <div className="col-xxl-7 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataConnectorsVisual2.svg"
                    width="80%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <h3 className="mb-5">Technical Capabilties</h3>
            <div className="row g-3">
              <div className="col-lg-4">
                <div className="card no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/dbConnector.svg"
                        alt="cynepia data connector"
                        width={75}
                      />
                    </div>
                    <h5 className="title">Bring all your data together</h5>
                    <div className="description">
                      All the data available in the data lake.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/periodicChange.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">
                      Periodic/Change Based Data Replication
                    </h5>
                    <div className="description">
                      Automated replication ensures your data to ensure your
                      data is available for analysis instantly.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/snapshot.svg"
                        alt="cynepia historical snapshot"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Historical Snaphots</h5>
                    <div className="description">
                      Your data is versioned always, No need to take data
                      snapshots of your important source datasets.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/automatedRefreshGear.svg"
                        alt="cynepia data connector"
                        width={75}
                      />
                    </div>
                    <h5 className="title">Automate Data Refresh</h5>
                    <div className="description">
                      Run Periodically or Trigger Datasource refresh Job on all
                      remote data connectors. Alert data owners for all refresh.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/bellActive.svg"
                        alt="cynepia data connector"
                        width={40}
                      />
                    </div>
                    <h5 className="title">
                      Know Source Schema Changes Instantly
                    </h5>
                    <div className="description">
                      Metadata change alert ensures you are on top of schema
                      changes at source.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactUsSection />
      </div>
    );
  }
}
