import React, { Component } from "react";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import "./actionButton.scss";

export default class ActionButtons extends Component {
  render() {
    return (
      <div
        className="action-buttons text-right mt-3"
        id="slideshow-action-buttons"
      >
        <div className="d-flex align-items-center w-100 justify-content-end gap-2">
          <div
            className="left-action border p-2 cursor-pointer border rounded"
            onClick={() => this.props.clickCb("left")}
          >
            <ChevronLeft
              className="text-secondary"
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
          </div>
          <div
            className="right-action border p-2 cursor-pointer border rounded"
            onClick={() => this.props.clickCb("right")}
          >
            <ChevronRight
              className="text-secondary"
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
