import React, { Component } from "react";
import CtaButtons from "../../ctaButtons/ctaButtons";
import GetStartedSection from "../../getStartedSection/getStartedSection";
import StoryData from "../../pages/stories/storyList/storyData";
import "./story.scss";
import RelatedStorySection from "./relatedStorySection";

export default class Story extends Component {
  getStoryData() {
    const { title } = this.props.match.params;
    return StoryData.getDataByDisplayUrl(title);
  }

  render() {
    const { title, shortDescription, cover_img, ChildComp, relatedStoryIds } =
      this.getStoryData();

    return (
      <div className="page position-relative" id="story-page">
        <div className="page-section pt-5">
          <div className="page-container container">
            <div className="row justify-content-between align-items-start mb-5">
              <div className="col-xl-6 col-lg-6 col-md-12 text-left">
                <h1>{title}</h1>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-12 text-left">
                <p className="mb-5 fs-5">{shortDescription}</p>
                <CtaButtons center={true} />
              </div>
            </div>
            <div
              style={{
                backgroundImage: "url(" + cover_img + ")",
              }}
              className="storyCoverImage"
            ></div>
          </div>
        </div>

        <ChildComp />
        <RelatedStorySection data={relatedStoryIds} />
        <div className="container">
          <GetStartedSection />
        </div>
      </div>
    );
  }
}
