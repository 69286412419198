const AutoAwesome = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8L19.25 5.25L22 4L19.25 2.75L18 0L16.75 2.75L14 4L16.75 5.25L18 8Z"
        fill="#5F6368"
      />
      <path
        d="M18 14L16.75 16.75L14 18L16.75 19.25L18 22L19.25 19.25L22 18L19.25 16.75L18 14Z"
        fill="#5F6368"
      />
      <path
        d="M10.5 8.5L8 3L5.5 8.5L0 11L5.5 13.5L8 19L10.5 13.5L16 11L10.5 8.5ZM8.99 11.99L8 14.17L7.01 11.99L4.83 11L7.01 10.01L8 7.83L8.99 10.01L11.17 11L8.99 11.99Z"
        fill="#5F6368"
      />
    </svg>
  );
};

const Terminal = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0H2C0.89 0 0 0.9 0 2V14C0 15.1 0.89 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.11 0 18 0ZM18 14H2V4H18V14ZM16 13H10V11H16V13ZM5.5 13L4.09 11.59L6.67 9L4.08 6.41L5.5 5L9.5 9L5.5 13Z"
        fill="#5F6368"
      />
    </svg>
  );
};

const SearchAwesome = () => {
  return (
    <svg
      width="128"
      height="134"
      viewBox="0 0 128 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.3368 87.6164H83.6907L81.6895 85.6867C88.6935 77.5392 92.9102 66.9617 92.9102 55.4551C92.9102 29.7976 72.1126 9 46.4551 9C20.7976 9 0 29.7976 0 55.4551C0 81.1126 20.7976 101.91 46.4551 101.91C57.9617 101.91 68.5392 97.6935 76.6867 90.6895L78.6164 92.6907V98.3368L114.351 134L125 123.351L89.3368 87.6164ZM46.4551 87.6164C28.6592 87.6164 14.2939 73.251 14.2939 55.4551C14.2939 37.6592 28.6592 23.2939 46.4551 23.2939C64.251 23.2939 78.6164 37.6592 78.6164 55.4551C78.6164 73.251 64.251 87.6164 46.4551 87.6164Z"
        fill="#5F6368"
      />
      <path
        d="M94 20L97.125 13.125L104 10L97.125 6.875L94 0L90.875 6.875L84 10L90.875 13.125L94 20Z"
        fill="#5F6368"
      />
      <path
        d="M114 35L109.625 44.625L100 49L109.625 53.375L114 63L118.375 53.375L128 49L118.375 44.625L114 35Z"
        fill="#5F6368"
      />
    </svg>
  );
};

export { AutoAwesome, Terminal, SearchAwesome };
