import React, { Component } from "react";
import { Link } from "react-router-dom";
import AnimatedArrowIconLink from "../animatedArrowIconLink/animatedArrowIconLink";
import "./cardItem.scss";

export default class CardItem extends Component {
	render() {
		const { thumbnail_img, title, shortDescription, displayUrl } =
			this.props.data;
		const link = `/story/${displayUrl}`;
		return (
			<div className="card card-item d-flex h-100">
				<div className="hover-scale-1 no-shadow flex-direction-column">
					<Link to={link}>
						<div
							className="card-img-container
								position-relative
								w-100
								bg-img-size-cover 
								bg-img-position-center
								cursor-pointer
							"
							style={{
								backgroundImage: "url(" + thumbnail_img + ")",
							}}
						></div>
					</Link>

					<div className="card-body p-5">
						<div className="d-flex align-items-center h-100">
							<div>
								<Link to={link}>
									<h5 className="card-title cursor-pointer">{title}</h5>
								</Link>
							</div>
						</div>
						<p className="text-secondary">{shortDescription}</p>
						{this.props.readMoreEnabled ? (
							<AnimatedArrowIconLink to={link} state content={"Read More"} />
						) : (
							""
						)}
					</div>
				</div>
			</div>
		);
	}
}
