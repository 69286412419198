import React, { Component } from "react";
import IndustrySpecific from "./industrySpecific/industrySpecific";
import RoleSpecific from "./roleSpecific/roleSpecific";

export default class StoriesDropdown extends Component {
  render() {
    return (
      <div className="nav-dropdown" id="storiesDropdown">
        <div className="container py-5 px-4">
          <div className="row align-items-strech">
            <div className="col-xxl-4 col-lg-4 col-lg-4 ">
              <IndustrySpecific />
            </div>

            <div className="col-xxl-8 col-lg-8 col-lg-8">
              <RoleSpecific />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
