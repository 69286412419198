import React, { Component } from "react";
import "./featuredBlogSection.scss";

import AnimatedArrowIconLink from "../animatedArrowIconLink/animatedArrowIconLink";
export default class FeaturedBlogSection extends Component {
  static getData() {
    return [
      {
        link:
          window.__BlogUrl__ +
          "introducing_xceed_ai_assistant",
        imgUrl: "images/ai_assistant.jpg",
        title: "Introducing Xceed AI Assistant",
      },
      {
        link: window.__BlogUrl__ + "introducing_no_code_data_quality_monitoring",
        imgUrl: "images/customerRiskManagement.jpg",
        title:
          "Introducing Xceed Data Quality Monitor"
      },
      {
        link:
          window.__BlogUrl__ +
          "is_end_to_end_data_and_ai_platform_answer_to_success_of_datascience_projects",
        imgUrl: "images/dataStratergy.jpg",
        title:
          "Is Unified Data & AI Platform answer to improving success of Data Science projects?",
      },

    ];
  }

  static onBlogCardClick(link) {
    window.open(link, "_blank");
  }

  render() {
    return (
      <div className="page-section" id="featuredBlogSection">
        <div className="page-container container">
          <h6 className="pre-heading-highlight">Featured Articles</h6>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="page-section-title">From Our Blog</h3>
            <AnimatedArrowIconLink
              clickCb={() => {
                FeaturedBlogSection.onBlogCardClick(window.__BlogUrl__);
              }}
              content="Read More Articles"
              classNames="target_blank"
            />
          </div>
          <div className="row flex-wrap blog-row justify-content-center">
            {FeaturedBlogSection.getData().map((item, i) => {
              return (
                <div className="col-lg-4 col-md-6 col-sm-10 col-xs-12" key={i}>
                  <div
                    className="blog-card no-shadow"
                    onClick={() => {
                      FeaturedBlogSection.onBlogCardClick(item.link);
                    }}
                  >
                    <div
                      className="card-img-container h-75 mb-3
									bg-img-size-cover bg-img-position-center
								"
                      style={{
                        backgroundImage: "url(" + item.imgUrl + ")",
                      }}
                    ></div>
                    <div className="blog-card-body">
                      <div className="d-flex align-items-center h-100">
                        <div>
                          <h6 className="card-title">{item.title}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
