import React from "react";
import { Link } from "react-router-dom";

const GetStartedButton = ({ classNames }) => {
  const openNewTab = () => {
    const url = window.__registerURL__; // Replace with your desired URL
    window.open(url, "_blank");
  };

  const btnClassNames = `button primary ${classNames ? classNames : ""}`;

  return (
    <Link to="/get-started">
      <button className={btnClassNames}>Get Started</button>
    </Link>
  );
};

export default GetStartedButton;
