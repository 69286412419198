import React from "react";
import GetStartedButton from "./getStartedButton";
import ContactUsButton from "./ContactUsButton";

export default function CtaButtons(props) {
  const classNames = ` 
    d-flex align-items-center gap-3 ${
      props.center ? "justify-content-center " : ""
    }
  `;

  return (
    <div className="cta-wrapper mb-5">
      <div className={classNames}>
        <GetStartedButton />
        {props.contactBtnHidden !== true && <ContactUsButton />}
      </div>
    </div>
  );
}
