import React, { Component } from "react";
import { YouTube, Twitter, LinkedIn } from "@material-ui/icons";
import "./socialIcons.scss";

export default class SocialIcons extends Component {
	render() {
		return (
			<div className={"social-icons " + this.props.bg || ""}>
				<span
					className="social-icon-link youtube"
					target="_blank"
					aria-label="Youtube"
					onClick={() => {
						window.open(
							"https://www.youtube.com/channel/UCjnouT0EobXCPAQGAdtG-kw/videos",
							"_blank"
						);
					}}
				>
					<YouTube></YouTube>
				</span>
				<span
					className="social-icon-link twitter"
					target="_blank"
					aria-label="Twitter"
					onClick={() => {
						window.open("https://twitter.com/Cynepia", "_blank");
					}}
				>
					<Twitter></Twitter>
				</span>
				<span
					className="social-icon-link linkedIn"
					target="_blank"
					aria-label="LinkedIn"
					onClick={() => {
						window.open(
							"https://in.linkedin.com/company/cynepia-technologies",
							"_blank"
						);
					}}
				>
					<LinkedIn></LinkedIn>
				</span>
			</div>
		);
	}
}
