import React, { Component } from "react";
import { Link } from "react-router-dom";
import GetStartedButton from "../../../ctaButtons/getStartedButton";

import "./productsDropdown.scss";

export default class ProductsDropdown extends Component {
  render() {
    return (
      <div className="nav-dropdown" id="productsDropdown">
        <div className="container py-5 px-4">
          <div className="row align-items-strech">
            <div className="col-xxl-4 col-lg-4 col-lg-4 ">
              <div className="list-wrapper d-flex flex-direction-column h-100">
                <div className="list-heading-wrapper d-flex gap-3 mb-5">
                  <span className="list-icon-wrap ">
                    <img
                      src="/images/icons/platformOverview.svg"
                      alt="cynepia platform overview icon"
                      width="30"
                    />
                  </span>
                  <span>
                    <h5>Platform Overview</h5>
                  </span>
                </div>
                <div className="list">
                  <div className="list-item d-flex gap-3">
                    <span className="list-icon-wrap ">
                      <img
                        src="/images/icons/architecture.svg"
                        alt="cynepia platform overview icon"
                        width="30"
                      />
                    </span>
                    <div className="list-item-body">
                      <h6>
                        <Link to="/products/architecture">Architecture</Link>
                      </h6>
                      <p className="medium-text text-secondary">
                        Intelligent data platform architecture brings data and
                        AI together accelaratating value creation using
                        generative AI
                      </p>
                    </div>
                  </div>
                  <div className="list-item d-flex gap-3 align-items-center">
                    <span className="list-icon-wrap ">
                      <img
                        src="/images/icons/pricing.svg"
                        alt="cynepia platform overview icon"
                        width="25"
                      />
                    </span>
                    <div className="list-item-body">
                      <h6 className="mb-0">
                        <Link to="/products/pricing">Pricing</Link>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="mt-auto">
                  <GetStartedButton />
                </div>
              </div>
            </div>

            <div className="col-xxl-8 col-lg-8 col-lg-8">
              <div className="list-wrapper">
                <div className="list-heading-wrapper d-flex gap-3 mb-5">
                  <span className="list-icon-wrap ">
                    <img
                      src="/images/icons/dataApplications.svg"
                      alt="cynepia data applications icon"
                      width="20"
                    />
                  </span>
                  <span>
                    <h5>Intelligent Data Applications</h5>
                  </span>
                </div>
                <div className="list row g-3 ">
                  <div className="col-lg-6">
                    <div className="list-item d-flex gap-3 ">
                      <span className="list-icon-wrap ">
                        <img
                          src="/images/icons/dataConnections.svg"
                          alt="cynepia data connections icon"
                          width="30"
                        />
                      </span>
                      <div className="list-item-body">
                        <h6>
                          <Link to="/products/data-connectors">
                            Data Connections
                          </Link>
                        </h6>
                        <p className="medium-text text-secondary">
                          Don't leave any data behind, Connect all your
                          enterprise data.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="list-item d-flex gap-3 ">
                      <span className="list-icon-wrap ">
                        <img
                          src="/images/icons/catalog_icon.svg"
                          alt="cynepia data catalog icon"
                          width="30"
                        />
                      </span>
                      <div className="list-item-body">
                        <h6>
                          <Link to="/products/data-catalog">Data Catalog</Link>
                        </h6>
                        <p className="medium-text text-secondary">
                          Enable governed access to data that users need
                          instantly
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="list-item d-flex gap-3 ">
                      <span className="list-icon-wrap ">
                        <img
                          src="/images/icons/flow.svg"
                          alt="cynepia data preparations icon"
                          width="30"
                        />
                      </span>
                      <div className="list-item-body">
                        <h6>
                          <Link to="/products/data-preparation">
                            Data Preparation & ML
                          </Link>
                        </h6>
                        <p className="medium-text text-secondary">
                          Low/no-code interface to build, run, and manage
                          data/ml models instantly
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="list-item d-flex gap-3 ">
                      <span className="list-icon-wrap ">
                        <img
                          src="/images/icons/sqlEditor.svg"
                          alt="SQL Workbench"
                          width="30"
                        />
                      </span>
                      <div className="list-item-body">
                        <h6>
                          <Link to="/products/xceed-analytics-sql-Workbench">
                            SQL Workbench
                          </Link>
                        </h6>
                        <p className="medium-text text-secondary">
                          Enable data analyst create, run and manage SQL
                          queries/models using Xceed SQL Workbench.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="list-item d-flex gap-3 ">
                      <span className="list-icon-wrap ">
                        <img
                          src="/images/icons/model_icon.svg"
                          alt="cynepia model catalog icon"
                          width="30"
                        />
                      </span>
                      <div className="list-item-body">
                        <h6>
                          <Link to="/products/model-catalog">
                            Model Catalog
                          </Link>
                        </h6>
                        <p className="medium-text text-secondary">
                          One place for all your ML & AI models. Streamline
                          model ops including version control, governance,
                          traceability and deployment
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="list-item d-flex gap-3 ">
                      <span className="list-icon-wrap ">
                        <img
                          src="/images/icons/self-service-bi.svg"
                          alt="cynepia data connections icon"
                          width="30"
                        />
                      </span>
                      <div className="list-item-body">
                        <h6>
                          <Link to="/products/self-service-bi">
                            Self-Service BI
                          </Link>
                        </h6>
                        <p className="medium-text text-secondary">
                          Empower Business Analysts/Users to build insights,
                          share instantly and drive actions
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="list-item d-flex gap-3 ">
                      <span className="list-icon-wrap ">
                        <img
                          src="/images/icons/searchIcon.svg"
                          alt="cynepia search icon"
                          width="30"
                        />
                      </span>
                      <div className="list-item-body">
                        <h6>
                          <Link to="/products/smart-ai-discovery-and-search">
                            Smart AI Discovery and Search
                          </Link>
                        </h6>
                        <p className="medium-text text-secondary">
                          Empower Business Analysts/Users to build insights,
                          share instantly and drive actions
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="list-item d-flex gap-3 ">
                      <span className="list-icon-wrap ">
                        <img
                          src="/images/icons/dataAgentIcon.svg"
                          alt="cynepia ai agents icon"
                          width="30"
                        />
                      </span>
                      <div className="list-item-body">
                        <h6>
                          <Link to="/products/ai-agents">
                            Create Agent Workforce for your enterprise
                          </Link>
                        </h6>
                        <p className="medium-text text-secondary">
                          Supercharge your enterprise by creating purpose built agents
	    		</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
