import React, { Component } from "react";

export default class AutoMlNoCodeUiFuture extends Component {
  render() {
    return (
      <div className="page-section">
        <div className="page-container container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="page-section">
                <div className="page-container container-flui">
                  <h2>Challenge</h2>
                  <h5>Lack of Enough Data Scientists</h5>
                  <p>
                    Demand for AI has been challenged by low supply of quality
                    data scientists/statistics and in general data resources,
                    who can cover.
                  </p>
                  <p>
                    However, the growing demand for AI is hampered by the very
                    low supply of data scientists and machine learning experts.
                    Among the efforts to address this talent gap is the
                    fast-evolving field of no-code AI, tools that make the
                    creation and deployment of ML models accessible to
                    organizations that don’t have enough highly skilled data
                    scientists and machine learning engineers.
                  </p>
                </div>
              </div>
              <div className="page-section">
                <div className="page-container">
                  <p className="highlight-quote-1 fs-4">
                    “The reason the demand for AI is going up so significantly
                    is because the amount of digital exhaust being generated by
                    businesses and the number of ways they can creatively use
                    that digital exhaust to solve real business problems is
                    going up,” - Bardoliwalla said.
                  </p>
                </div>
              </div>
              <div className="page-section">
                <div className="page-container">
                  <h3>Is AutoML/No-Code ML the future?</h3>
                  <p>
                    AutoML essentially helps to replace some of the manual
                    repetitive nature of work that a Data Scientist does and
                    helps him/her become more productive and focus on the
                    delivery of business value and tasks which essentially
                    cannnot be automated.
                  </p>
                  <p>
                    There has been a lot of debate and research papers published
                    on the usefulness of automl and also prior attempts of
                    building an automl library from the opensource/commercial
                    vendors.
                  </p>
                  <p>
                    We beleive that AutoML coupled with no-code-ui can be useful
                    in multiple ways:
                  </p>
                  <ol>
                    <li>
                      Enable quick experiementation and rapid prototyping, often
                      required before building a deeper model.
                    </li>
                    <li>
                      Bring in a new class of Domain Experts, who understand
                      where and how ML can be applied, however may have limited
                      understanding of ML Process itself and the programming
                      that comes along.
                    </li>
                    <li>
                      Enhance the productivity of the Expert DS/MLE. Expert
                      DSes/MLE can just be as excited about AutoML, because They
                      now have help available to offload any
                      repetitive/iterative part of their work. A DS/MLE also
                      understands the core value of their work lies in working
                      with Business Leaders to define the problem well, in
                      getting the task definitions clarified, staying in touch
                      on the possible business needs, understanding the
                      data/features that would be required to put create the
                      right input for the model training phase and further
                      downstream explaining the model outcomes to the business
                      leaders and recommending next steps besides the overall
                      strategy of integrating the same with the larger
                      enterprise software ecosystem.
                    </li>
                    <li>
                      There are also a set of DS use cases that automl doesn’t
                      cater to and would continue to be the other focus areas
                      for the DS/MLE. DSes also can find more time on
                      researching deeper use cases, if the iterative/repetitive
                      work is offloaded.
                    </li>
                  </ol>
                  <p>
                    At Cynepia we see that AutoML will coexist with the
                    traditional handcoded models in a fairly complementary way.
                  </p>
                </div>
              </div>
              <div className="page-section">
                <div className="page-container">
                  <h3>A Typical DS/MLE Workflow</h3>
                  <div className="img-wrapper">
                    <img
                      src="/images/dsMleWorkflow.png"
                      alt="cynepia ds mle workflow"
                      width="100%"
                    />
                  </div>
                  <h3>How does Xceed AutoML work?</h3>
                  <p>
                    Xceed AutoML seeks to automate the following steps involved
                    in model training specifically for the classical machine
                    learning use-cases. These use cases include Classification,
                    Regression & Clustering.
                  </p>
                  <p>
                    A DS or MLE picks the features, target, evaluation criteria,
                    test/train split strategy, a set of learners, potentially
                    tune/select a set of hyperparameter ranges/values to tune,
                    ensemble size and search algorithm/parameters and fires the
                    model training through a form based user interface.
                  </p>
                  <p>
                    Xceed AutoML takes it further from there. It takes the
                    approach of not just tuning the hyperparameters but tuning
                    the whole machine learning pipeline and all the steps
                    involved in the pipeline. It further leverages ray & ray
                    tune, a general-purpose and universal distributed compute
                    framework, and scalable hyperparameter tuning library to
                    flexibly run all the potential pipelines in parallel.
                    Following are the steps are involved in any Xceed AutoML
                    pipeline.
                  </p>
                  <h5>a) Test Train Split </h5>
                  <p>
                    Perform test/train split based on the user picked strategy.
                  </p>
                  <h5>b) Data preprocessing </h5>
                  <p>This includes potentially the following preprocessors.</p>
                  <ol>
                    <li>
                      Null Value Imputation: Fill Null values based on one of
                      the available strategies
                    </li>
                    <li>
                      Categorical Features: Applying Label Encoding/One Hot
                      Encoding/Multilabel binarizer Features to Categorical X
                      features and Label Encoding Target variable, if
                      categorical.
                    </li>
                    <li>
                      Numeric Features: Apply Scaling based on one of the
                      available strategies
                    </li>
                  </ol>
                  <h5>c) Feature Selection </h5>
                  <p>
                    Reduce the number of features based on one of the available
                    feature selection strategy
                  </p>
                  <h5>d) Hyperparamter Tuning</h5>
                  <p>
                    Xceed AutoML chooses the potential ranges or list of values
                    for each of the hyperparameters for the available learners.
                    It further uses one of the hyperparameter search algorithms
                    such as random search or grid search to select the
                    hyperparameters from the search space.
                  </p>
                  <h5>e) Training one or more learners:</h5>
                  <p>
                    Xceed AutoML heavily relies on many of state of the art
                    algorithms and ensembling techniques from scikit-learn and
                    python ecosystem which are popular with the Data
                    Scientists/MLE.{" "}
                  </p>
                  <h5>f) Validation & Testing:</h5>
                  <p>
                    It further validates each of the trained model against a
                    validation dataset and ranks the models based on the metric
                    selected by the DS/MLE and stores the tuned models in a
                    ranked list of models. It further creates an ensemble of the
                    top models from the list based on the ensemble size picked
                    by the DS/MLE. Integrated Visualization further helps the
                    MLE/DS to interpret/explain models quickly. Xceed AutoML
                    supports a variety of visualization techniques depending on
                    the class of use-cases including Feature Importance Matrix,
                    Confusion Matrix, ROC Curve, Precision vs Recall, Cumulative
                    Gain Curve/Lift Curve, Output table with probability
                    distribution, Residual Curve, y-true vs y-predicted chart.
                    Besides the above Xceed AutoML Visualization also supports
                    independent explainability and localized interpretability
                    using shapley values and sub-segment analysis.
                  </p>
                  <h5>g) Model Evaluation/Selection & Recommendation</h5>
                  <p>
                    Once Trained model can be published to model catalogue,
                    where model is versioned and configuration/results archived.
                    Once published, the model can easily be deployed into a
                    Production instance on the cloud as well where model
                    monitoring checks can be setup.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
