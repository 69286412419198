import EndToEndAnalyticsMicrofinance from "../storyPages/EndToEndAnalyticsMicrofinance";
import FindExploreAndGovern from "../storyPages/findExploreAndGovernData";
import KnowYourCustomer from "../storyPages/knowYourCustomer";
import KnowYourBranchNetwork from "../storyPages/knowYourBranchNetwork";
import AutoMlNoCodeUiFuture from "../storyPages/autoMlNoCodeUiFuture";
import SpeedUpReportingDashboards from "../storyPages/speedUpReportingDashboards";
import CollaborationWorkspace from "../storyPages/collaborationWorkspace";
const data = [
  {
    id: "1",
    title: "End to End Analytics at a Finance Institution",
    shortDescription:
      "Financial Institutions are sitting on diverse data from various sources including banking CRM, HR and other internal databases including local files and external SAAS apps., Bringing disparate data together and building and upkeeping data models which are further used by downstream use-cases can be a significant challenge.",
    displayTags: ["Finance"],
    roles: ["data-engineer", "data-analyst"],
    industry: ["financial-services"],
    cover_img: "/images/analytics_cover.jpg",
    thumbnail_img: "/images/analytics_thumbnail.jpg",
    displayUrl: "end-to-end-analytics-microfinance",
    ChildComp: EndToEndAnalyticsMicrofinance,
    relatedStoryIds: ["4", "5", "7"],
  },
  {
    id: "2",
    title: "Find, Explore & Govern Data",
    shortDescription:
      "An integrated Data Catalog not just acts as a central knowledge repository of all your data assets and make it easily for every one who needs access to find, search and discover data assets.",
    displayTags: ["Data Engineer"],
    roles: ["data-engineer", "data-scientist", "data-analyst"],
    industry: "all",
    ChildComp: FindExploreAndGovern,
    displayUrl: "find-explore-govern-data",
    cover_img: "/images/explore_data_cover.jpg",
    thumbnail_img: "/images/explore_data_thumbnail.jpg",
    relatedStoryIds: ["3", "6", "7"],
  },
  {
    id: "3",
    title: "Collaboration Workspace for all your data assets and data teams",
    shortDescription:
      "Communication of data events such as new data availability, period updates to existing datasets can significantly improve collaboration & productivity of various data roles as well as business consumers",
    displayTags: ["Data Engineer", "Data Analyst"],
    roles: ["data-engineer", "data-analyst"],
    industry: "all",
    ChildComp: CollaborationWorkspace,
    displayUrl:
      "collaboration-workspace-for-all-your-data-assets-and-data-teams",
    cover_img: "/images/collaborate_workspace_cover.jpg",
    thumbnail_img: "/images/collaborate_workspace_thumbnail.jpg",
    relatedStoryIds: ["2", "5", "6"],
  },
  {
    id: "4",
    title: "Know your customer",
    shortDescription:
      "Getting a complete view of the customer can help understand personalize business product offerings to suite the needs of the customer and thereby improving customer's affinity with the organisation, Customer Data Models can provide a 360 degree understanding of every unique customer in a consumer facing business.",
    displayTags: ["Data Engineer", "Data Analyst"],
    roles: ["data-engineer", "data-analyst"],
    industry: "all",
    ChildComp: KnowYourCustomer,
    displayUrl: "know-your-customer",
    cover_img: "/images/know_your_customer_cover.jpg",
    thumbnail_img: "/images/know_your_customer_thumbnail.jpg",
    relatedStoryIds: ["1", "5", "7"],
  },
  {
    id: "5",
    title: "Know your Branch Network",
    shortDescription:
      "Retail Banks/Financial Institutions with large branch often face a daunting challenge to understand individual branch performance deeper. Branch Data Models have the potential to provide a 360 degree understanding of Branch Operations in a retail banking scenario.",
    displayTags: ["Data Engineer", "Data Analyst"],
    roles: ["data-engineer", "data-analyst"],
    industry: "all",
    ChildComp: KnowYourBranchNetwork,
    displayUrl: "know-your-branch-network",
    cover_img: "/images/knowYourBranchNetwork_cover.jpg",
    thumbnail_img: "/images/knowYourBranchNetwork_thumbnail.jpg",
    relatedStoryIds: ["1", "4", "7"],
  },
  {
    id: "6",
    title: "Is AutoML and No Code UI Future of ML/AI?",
    shortDescription:
      "No Code Data Science and AutoML can significantly improve the productivity, execution speed and success of data science projects in the enterprise",
    displayTags: ["Data Engineer", "Data Analyst", "Data Scientist"],
    roles: "all",
    industry: "all",
    ChildComp: AutoMlNoCodeUiFuture,
    displayUrl: "auto-ml-no-code-future-of-ml-ai",
    cover_img: "/images/ai_cover.jpg",
    thumbnail_img: "/images/ai_thumbnail.jpg",
    relatedStoryIds: ["3", "2", "7"],
  },
  {
    id: "7",
    title: "Speed up Reporting Dashboards",
    shortDescription:
      "Real-time dashboards can significantly speed up reporting while ensuring data integrity with integrated Datasets Xceed Catalog.",
    displayTags: ["Data Engineer", "Data Analyst", "Data Scientist"],
    roles: "all",
    industry: "all",
    ChildComp: SpeedUpReportingDashboards,
    displayUrl: "speed-up-reporting-dashboards",
    cover_img: "/images/speedUpDashboards_cover.jpg",
    thumbnail_img: "/images/speedUpDashboards_thumbnail.jpg",
    relatedStoryIds: ["1", "3", "2"],
  },
];

const getDataById = (id) => {
  return data.find((item) => item.id === id);
};

const getDataByTitle = (title) => {
  return data.find((item) => item.title === title);
};

const getDataByDisplayUrl = (displayUrl) => {
  return data.find((item) => item.displayUrl === displayUrl);
};

const getDataByIndustry = (industries) => {
  /** tags needs to be array
   * finding if tags has common elements to the data tags
   */
  return data.filter((item) => {
    if (item.industry) {
      if (item.industry === "all") {
        return true;
      }
      if (Array.isArray(item.industry)) {
        return item.industry.some((ai) => industries.includes(ai));
      }
    }
    return false;
  });
};

const getDataByRole = (roles) => {
  /** tags needs to be array
   * finding if tags has common elements to the data tags
   */
  return data.filter((item) => item.role.some((ai) => roles.includes(ai)));
};

const getDataBySearchQuery = (query) => {
  return data.filter((item) => {
    const { shortDescription, tags, title } = item;
    return (
      shortDescription.search(query) ||
      tags.filter((tag) => tag.search(query)) ||
      title.search(query)
    );
  });
};

const getDataByIndustriesAndRoles = (obj) => {
  let { industry, role } = obj;
  industry = industry ? industry : [];
  role = role ? role : [];

  return data.filter((item) => {
    let status = false;
    if (item.roles) {
      if (item.roles === "all") return true;
      if (item.roles.some((ai) => role.includes(ai))) {
        status = true;
      }
    }
    if (item.industry) {
      if (item.industry === "all") return true;
      if (item.industry.some((ai) => industry.includes(ai))) {
        status = true;
      }
    }
    return status;
  });
};

const getAll = () => {
  return data;
};

const exportVar = {
  getDataById,
  getDataByTitle,
  getDataByIndustry,
  getDataBySearchQuery,
  getDataByRole,
  getDataByIndustriesAndRoles,
  getDataByDisplayUrl,
  getAll,
};

export default exportVar;
