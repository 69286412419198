import React, { Component } from "react";
import SocialIcons from "../../socialIcons/socialIcons";
import { Link } from "react-router-dom";
import "./ContactUs.scss";

import { CountryList, DialCodeList } from "../../../utilityData/utilityData";

const timezones = require("timezones.json");

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
      formSubmitStatus: null,
      requestOnGoing: false,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    debugger;
    const {
      firstname,
      lastname,
      dialcode,
      phone,
      country,
      zip,
      scheduledate,
      last_schedule_meeting_time_zone,
      email,
      message,
    } = e.target.elements;

    let bodyParams = {
      firstname: firstname.value,
      lastname: lastname.value,
      phone: dialcode.value + phone.value,
      country: country.value,
      zip: zip.value,
      scheduledate: scheduledate.value,
      last_schedule_meeting_time_zone: last_schedule_meeting_time_zone.value,
      email: email.value,
      lead_source: "Web-Contact-Us",
      message: message.value,
    };

    const params = JSON.stringify(bodyParams);
    const encodedParams = window.btoa(params);

    let url = window.__CreateContact__ + `/${encodedParams}`;

    this.setState({
      requestOnGoing: true,
    });

    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        let formSubmitStatus, formSubmitted;
        formSubmitted = true;
        if (res.status === 200) {
          formSubmitStatus = "Success";
        } else {
          formSubmitStatus = "Failed";
          console.error(res);
        }
        this.setState({
          requestOnGoing: false,
          formSubmitStatus,
          formSubmitted,
        });
      });
  }

  render() {
    return (
      <div className="page " id="contactUsPage">
        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-start justify-content-between g-5">
              <div className="col-lg-5">
                <div className="left-section-wrapper">
                  <h6 className="pre-heading-highlight">Let's Connect</h6>
                  <h1>Contact Us</h1>
                  <p className="fs-5 text-secondary f-w-400 mb-5">
                    Fill out the form and we will reach out to you.
                  </p>
                  <div className="">
                    <h5 className="mb-3">How to reach us ?</h5>
                    <div className="container-fluid p-0">
                      <div className="row">
                        <div className="col-lg-12 col-md-6 col-sm-6 col-6 ">
                          <div className="card-wrapper d-flex flex-direction-row gap-3 shadow-none p-2">
                            <div className="img-wrapper mb-3 d-inline">
                              <img
                                src="/images/icons/phone.svg"
                                alt="phone"
                                width="20"
                              />
                            </div>
                            <span className="d-inline">
                              <h5 className="">Phone</h5>
                              <p className="text-secondary ">
                                +91 - 973 193 3997
                              </p>
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-6 col-6">
                          <div className="card-wrapper d-flex flex-direction-row gap-3 shadow-none p-2">
                            <div className="img-wrapper mb-3">
                              <img
                                src="/images/icons/mail.svg"
                                alt="email"
                                width="20"
                              />
                            </div>
                            <span className="d-inline">
                              <h5 className="">Mail us at</h5>
                              <p className="text-secondary ">
                                sales@cynepia.com
                              </p>
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 ">
                          <div className="card-wrapper d-flex flex-direction-row gap-3 shadow-none p-2">
                            <div className="img-wrapper mb-3">
                              <img
                                src="/images/icons/location.svg"
                                alt="location"
                                width="20"
                              />
                            </div>
                            <span className="d-inline">
                              <h5 className="">Location</h5>
                              <p className="text-secondary ">
                                Cynepia Technologies,
                                <br />
                                Survey No. 58, 7, Outer Ring Rd, Bellandur,
                                Bengaluru, Karnataka 560103, INDIA.
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <SocialIcons bg="rounded colored"> </SocialIcons>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="form-wrapper px-5 py-5 bg-white border rounded">
                  {this.state.formSubmitted ? (
                    this.state.formSubmitStatus === "Success" ? (
                      <>
                        <div className="alert alert-success" role="alert">
                          Thank you for submitting the form. Check your mail for
                          confirmation. We will see you soon.
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="alert alert-danger" role="alert">
                          Something went wrong! Please try again.
                        </div>
                      </>
                    )
                  ) : (
                    ""
                  )}
                  <h5 className="mb-4">Send us a message</h5>
                  <form
                    className="cyn-form"
                    onSubmit={(e) => this.handleSubmit(e)}
                  >
                    <div className="mb-3">
                      <div className="row g-3 align-items-center">
                        <div className="col-lg-6 col-md-12">
                          <label
                            className="form-label text-secondary text-small"
                            htmlFor="firstName"
                          >
                            First Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstname"
                            aria-describedby="firstName"
                            placeholder="Enter first name"
                            maxLength="20"
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <label
                            className="form-label text-secondary text-small"
                            htmlFor="lastName"
                          >
                            Last Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastname"
                            aria-describedby="lastName"
                            placeholder="Enter last name"
                            maxLength="20"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="row g-3 align-items-end">
                        <div className="col-lg-6">
                          <label
                            className="form-label text-secondary text-small"
                            htmlFor="companyEmail"
                          >
                            Company Email *
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="companyEmail"
                            name="email"
                            aria-describedby="emailHelp"
                            placeholder="Enter your company email"
                            maxLength="40"
                            required
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="w-100 form-label text-secondary text-small">
                            Enter mobile number with country code
                          </label>
                          <div className="d-flex w-100 gap-3 align-items-end">
                            <div className="w-30">
                              <select
                                className="form-control"
                                name="dialcode"
                                required
                                defaultValue=""
                              >
                                <option value="" disabled>
                                  00
                                </option>
                                {DialCodeList.map((code, i) => {
                                  return (
                                    <option key={i} value={code}>
                                      {code}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="w-70">
                              <input
                                type="number"
                                className="form-control"
                                id="phone"
                                name="mobilephone"
                                aria-describedby="phoneHelp"
                                placeholder="company number"
                                maxLength="15"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="row g-3">
                        <div className="col-lg-8">
                          <label
                            className="form-label text-secondary text-small"
                            htmlFor="country"
                          >
                            Country *
                          </label>
                          <select
                            className="form-select"
                            id="country"
                            name="country"
                            required
                            defaultValue=""
                          >
                            <option value="" disabled>
                              Select your country
                            </option>
                            {CountryList.map((name) => {
                              return (
                                <option key={name} value={name}>
                                  {name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-4">
                          <label
                            className="form-label text-secondary text-small"
                            htmlFor="zipCode"
                          >
                            Zip / Postal Code *
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="zipCode"
                            aria-describedby="zipcode"
                            placeholder="Zip / Postal Code"
                            name="zip"
                            maxLength="10"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="row g-3">
                        <div className="col-lg-6">
                          <label
                            htmlFor="dateTime"
                            className="form-label text-secondary text-small"
                          >
                            Schedule Meeting *
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            id="dateTime"
                            aria-describedby="meetingDateTime"
                            name="scheduledate"
                            required
                          />
                        </div>
                        <div className="col-lg-6">
                          <label
                            className="form-label text-secondary text-small"
                            htmlFor="timezone"
                          >
                            Time Zone *
                          </label>
                          <select
                            className="form-select"
                            id="timezone"
                            name="last_schedule_meeting_time_zone"
                            defaultValue=""
                            required
                          >
                            <option value="" disabled>
                              Select time zone (Utc )
                            </option>
                            {timezones.map((item, i) => {
                              const { text } = item;
                              return (
                                <option key={i} value={text}>
                                  {text}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        className="form-label text-secondary text-small"
                        htmlFor="message"
                      >
                        Message (optional)
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        rows="5"
                        placeholder="Tell us about your requirement in no more than 300 words"
                        maxLength="300"
                      />
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        defaultChecked={true}
                        type="checkbox"
                        value="newsletter"
                        id="newsletter"
                      />
                      <label
                        className="form-check-label fs-6"
                        htmlFor="newsletter"
                      >
                        Sign me up for newsletter
                      </label>
                    </div>

                    <div className="mb-3">
                      <button
                        type="submit"
                        className="button primary w-100"
                        disabled={this.state.requestOnGoing}
                      >
                        {this.state.requestOnGoing ? (
                          <>
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </>
                        ) : (
                          "Contact Us"
                        )}
                      </button>
                    </div>
                  </form>
                  <small className="text-secondary">
                    By submitting this form you agree to our{" "}
                    <Link to="/privacy">Privacy Policy</Link> and our{" "}
                    <Link to="/terms">Terms of Use</Link>.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
