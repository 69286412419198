import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListComponent from "../../listComponent/listComponent";
export default class DataDrivenCulture extends Component {
  getList() {
    return [
      {
        title: "Data Engineer",
        link: "/role/data-engineer",
        imgSrc: "/images/icons/dataEngineer.svg",
      },
      {
        title: "Data Analyst",
        link: "/role/data-analyst",
        imgSrc: "/images/icons/dataAnalyst.svg",
      },
      {
        title: "Data Scientist",
        link: "/role/data-scientist",
        imgSrc: "/images/icons/dataScientist.svg",
      },
      {
        title: "Business User",
        link: "/products/self-service-bi",
        imgSrc: "/images/icons/finance.svg",
      },
    ];
  }

  static getListData() {
    return [
      "Accelarate the speed of innovation and use cases by empowering citizen data scientists and engineers to build powerful solutions.",
      "Easy-to-use modern visual editor let even non-programmers transform data, build and deploy models.",
      "Do-it-yourself self-service interface enables business users/analysts create and share visually appealing stories using a wide range of charts and visualizations.",
    ];
  }

  render() {
    return (
      <div className="page-section" id="dataDrivenCulturePageSection">
        <div className="page-container container">
          <div className="row align-items-center g-5">
            <div className="col-xxl-6 col-lg-6">
              <h6 className="pre-heading-highlight fw-bold">
                Create Data Driven Culture
              </h6>
              <h3>
                Empower Citizen Data Engineers & Scientists to enable powerful
                insights.
              </h3>
              <ListComponent data={DataDrivenCulture.getListData()} />
            </div>
            <div className="col-xxl-6 col-lg-6">
              <div className="list-card-wrap text-wrap">
                {this.getList().map((data, i) => {
                  const { imgSrc, title, link } = data;
                  return (
                    <div
                      key={i}
                      className="customCard p-4 text-center card box-shadow-3"
                    >
                      <Link to={link}>
                        <div className="img-wrapper">
                          <img src={imgSrc} alt="cynepia" />
                        </div>
                        <div className="card-body-wrap">
                          <h6>{title}</h6>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
