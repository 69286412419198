import React, { Component } from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";
import NavItems from "./navItems";
import { withRouter } from "react-router-dom";
import ProductsDropdown from "./subNavComponents/productsDropdown/productsDropdown";
import StoriesDropdown from "./subNavComponents/storiesDropdown/storiesDropdown";
import CompanyDropdown from "./subNavComponents/companyDropdown/companyDropdown";
import SideNav from "./sideNav/sideNav";
import LearnDropdown from "./subNavComponents/learnDropdown/learnDropdown";
import { Transition } from "semantic-ui-react";
import Announcement from "../Announcement/Announcement.jsx";
import GetStartedButton from "../ctaButtons/getStartedButton";

export default withRouter(
  class Navbar extends Component {
    constructor(props) {
      super(props);
      this.state = {
        desktopVersion: true,
        scrollClass: false,
        sidenavVisible: false,
        navBar: {
          visible: false,
        },
      };

      this.ref = React.createRef();
    }

    componentDidMount() {
      window.addEventListener("scroll", this.onWindowScroll.bind(this));
      window.addEventListener("resize", this.handleResize.bind(this));

      if (this.props.history) {
        this.routeListener = this.props.history.listen(() => {
          this.onRouteChange();
        });
      }
      // this.updateScrollClass();
      this.setState({
        navBar: {
          visible: true,
        },
      });
    }

    componentWillUnmount() {
      window.removeEventListener("scroll", this.onWindowScroll.bind(this));
      window.removeEventListener("resize", this.handleResize.bind(this));
    }

    handleResize() {
      if (window.innerWidth > 1200) {
        const body = document.querySelector("body");
        body.classList.remove("overflow-hidden");
      }
    }

    onRouteChange() {
      window.scrollTo(0, 0);
      if (this.state.sidenavVisible) {
        this.setState((prevState) => ({
          sidenavVisible: true,
        }));
        this.onHamburgerClick();
      }
    }

    static getLinks() {
      return [
        {
          id: "Home",
          to: "/",
          title: "Home",
        },
        {
          id: "Product",
          title: "Product",
          dropdown: <ProductsDropdown />,
        },
        {
          id: "Stories",
          dropdown: <StoriesDropdown />,
          to: "/stories",
          title: "Stories",
        },
        {
          id: "Learn",
          dropdown: <LearnDropdown />,
          title: "Learn",
        },
        {
          id: "Company",
          title: "Company",
          dropdown: <CompanyDropdown />,
        },
      ];
    }

    onWindowScroll() {
      this.updateScrollClass();
    }

    updateScrollClass(_scrollBool) {
      this.setState({ scrollClass: window.scrollY >= 10 });
    }

    addOrRemoveScrollClass(scrollBool) {
      const scrollClass = "scroll";
      const nav = this.ref.current.querySelector(".nav-wrapper");
      if (scrollBool) {
        nav.classList.add(scrollClass);
      } else {
        nav.classList.remove(scrollClass);
      }
    }

    setToActive(navItemId) {
      const nav = this.ref.current.querySelector(".nav-wrapper");
      const query = `[data-id='${navItemId}']`;
      const navItem = nav.querySelector(query);
      Array.from(nav.querySelectorAll(".nav-link")).forEach(function (el) {
        el.classList.remove("active");
      });
      navItem.classList.add("active");
    }

    onClick(navItemId) {
      this.setToActive(navItemId);
    }

    onHamburgerClick() {
      const el = this.ref.current;
      const hamburger = el.querySelector("#hamburger");
      const sidenav = el.querySelector("#mySidenav");
      const body = document.querySelector("body");
      if (!this.state.sidenavVisible) {
        sidenav.classList.add("show");
        hamburger.classList.add("close");
        body.classList.add("overflow-hidden");
        this.setState({ scrollClass: false });
      } else {
        sidenav.classList.remove("show");
        hamburger.classList.remove("close");
        body.classList.remove("overflow-hidden");
        this.onWindowScroll();
      }
      this.setState((prevState) => ({
        sidenavVisible: !prevState.sidenavVisible,
      }));
    }

    renderDropdown(itemId) {
      debugger;
    }

    render() {
      return (
        <div className="navOuter" ref={this.ref}>
          <Announcement show={!this.state.scrollClass} />
          <Transition
            visible={this.state.navBar.visible}
            animation="scale"
            duration={0}
          >
            <div
              className={`nav-wrapper top-announce-connect ${
                this.state.scrollClass ? "scroll" : ``
              } ${this.state.sidenavVisible ? "sideNavActive" : ""}`}
            >
              <div id="menu-overlay"></div>

              <nav className="nav-container container">
                <Link to="/" className="navbar-logo">
                  <img
                    src="/images/logos/logo_dark.svg"
                    alt="Cynepia"
                    width={130}
                  ></img>
                </Link>
                <div className="main-menu">
                  <NavItems
                    links={Navbar.getLinks()}
                    showDropdown={(itemId) => {
                      this.renderDropdown(itemId);
                    }}
                    history={this.props.history}
                  ></NavItems>
                </div>
                <div className="nav-cta">
                  <GetStartedButton
                    classNames={
                      this.state.scrollClass ? "button primary " : " basic "
                    }
                  />
                </div>
                <div className="nav-mobile-menu-icon position-relative">
                  <div className="hamburger-wrapper">
                    <button
                      type="button"
                      className="hamburger"
                      id="hamburger"
                      onClick={() => this.onHamburgerClick()}
                    >
                      <span className="hamburger-text-wrap">
                        <span className="hamburger-text-open hamburger-text">
                          menu
                        </span>
                        <span className="hamburger-text-close hamburger-text">
                          close
                        </span>
                      </span>
                      <span className="hamburger-line-wrap">
                        <span className="hamburger-line hamburger-line-1"></span>
                        <span className="hamburger-line hamburger-line-2"></span>
                        <span className="hamburger-line hamburger-line-3"></span>
                      </span>
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          </Transition>
          <SideNav history={this.props.history} />
        </div>
      );
    }
  }
);
