import React, { useEffect } from "react";

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="page" id="privacypage">
        <div className="page-section">
          <div className="page-container container">
            <h1>Terms Of Use</h1>
            <div className="fs-5 mb-4">Last Updated 19th Jan 2022</div>
            <p>
              This version of Terms of Use has been in effect since 19th August
              2016. Use of this site signifies your acceptance to the terms and
              conditions of this Terms of Use.
            </p>
            <p>
              This website www.cynepia.com is owned and managed by Cynepia
              Technologies Pvt. Limited, a company incorporated under the Indian
              laws, having its registered office at office at A/604, Adarsh Palm
              Retreat, Sarjapur-Marthahalli Outer Ring Road, Bangalore,
              Karnataka – 560103, India (hereinafter "Cynepia").
            </p>
            <p>
              This Terms of Use Agreement ("the Agreement") describes the terms
              and conditions applicable to your use of the services provided
              through website www.cynepia.com or any affiliated website ("the
              Site").
            </p>
            <p>
              You have entered into this Agreement as "the User" of the Site and
              Cynepia has entered into this Agreement as the owner of the Site.
            </p>
            <p>
              This agreement is published in compliance with the relevant
              provisions of various Indian laws including:
            </p>
            <ol>
              <li>The Indian Contract Act, 1872</li>
              <li>
                The Information Technology Act, 2000, [As amended by Information
                Technology (Amendment) Act 2008];
              </li>
              <li>
                The Information Technology (Reasonable Security Practices and
                Procedures and Sensitive Personal Information) Rules, 2001 (the
                “SPI Rules”) and
              </li>
              <li>
                The Information Technology (Intermediate Guidelines) Rules,
                2011.
              </li>
            </ol>
            <strong>Terms and Conditions</strong>
            <ol>
              <li>
                “User” for the purpose of this Agreement, wherever the context
                so requires, ‘you’ and ‘your’ shall relate to any natural or
                legal person who has agreed to become a User on the website by
                providing registration data while registering on the site. The
                User includes the person using this Site, and any legal entity,
                which may be represented by such person under actual or apparent
                authority.
                <ol>
                  <li>
                    If the User is a business entity, then the person using the
                    services provided by the Site represents that:
                    <ol>
                      <li>
                        such person has the authority to bind the entity to this
                        Agreement;
                      </li>
                      <li>
                        such person, when registering, uses the address of the
                        principal place of business of such business entity;and
                      </li>
                      <li>
                        all other information submitted on the Site, as well as
                        on affiliated websites during the registration process
                        by such person is true, accurate, current and complete.
                      </li>
                    </ol>
                  </li>
                  <li>
                    For purposes of this provision, a branch or representative
                    office will not be considered a separate entity and its
                    principal place of business will be deemed to be that of its
                    head office.
                  </li>
                </ol>
              </li>
              <li>
                "Affiliates" means entities controlling, controlled by or under
                common control with Us.
              </li>
              <li>
                “Documentation” means the written and/or electronic release
                notes, implementation guides, or other published technical
                documentation about the applicable Software as a Service
                (hereinafter “SaaS”) product.
              </li>
              <li>
                “License Term” means the term of the access rights granted for
                the SaaS Product, as identified in the relevant Order, starting
                when Cynepia delivers to Customer the relevant credentials to
                access and use the SaaS Product.
              </li>
              <li>
                “Order’ means any purchase order, product schedule or ordering
                document between Customer and Cynepia.
              </li>
              <li>
                “SaaS Product” means the [-please insert your software product
                name] services made available by access to and use of software
                hosted by Cynepia to which Customer has purchased a license
                under the relevant Purchase Order.
              </li>
              <li>
                "Third Party Rights” shall mean and include third party
                copyrights, trademarks, trade secret rights, patents and other
                personal or proprietary rights affecting or relating to material
                or information displayed on the Site, including but not limited
                to rights of personality and rights of privacy, or affecting or
                relating to products that are offered or displayed on the Site.
              </li>
              <li>
                In this Agreement, the words “you” or “your” or “User” or any
                similar words as the context shall collectively imply a vendor,
                a buyer, and any visitor on the Site. In this Agreement “Cynepia
                Technologies Pvt. Ltd.”, “We”, “Us”, “Our” or any similar words
                as the context may require refer to “Cynepia”.
              </li>
              <li>
                By accessing or using the services provided by the Site, you
                hereby agree to accept the terms and conditions set forth in
                this Agreement and the Privacy Policy as the User. You shall be
                bound by the terms and conditions of this Agreement with respect
                to your access to or using the services provided by the Site,
                and any further upgrade, modification, addition, or change to
                this Site. If you do not accept all of the terms and conditions
                of this Agreement, then you should not access or use the
                services provided by the Site. Violation of any of the terms of
                this Agreement will result in the termination of your Account or
                you may be prevented from accessing the Site and availing the
                Service.
              </li>
              <li>
                By visiting the Site or by registering with the services or by
                accepting this Agreement, you represent and warrant to Us that
                you are at least 18 years of age and that you have the right,
                authority and capacity to use the Site.
              </li>
              <li>
                The Agreement is applicable to the Site as a whole. However,
                certain sections of the Site may set out additional terms and
                conditions applicable for you, which will be notified separately
                in such sections. Under no circumstances, shall the provisions
                of such terms and conditions prevail over this Agreement.
              </li>
              <li>
                We may amend this Agreement at any time by posting the amended
                and restated Agreement on the Site. Such amended and restated
                Agreement shall bind you and Us immediately upon posting. The
                last revision date of the Agreement shall be mentioned at the
                beginning of the Agreement. Posting by Us of the amended and
                restated Agreement and your continued use of the Site shall be
                deemed to be acceptance of the amended terms. This Agreement may
                not be modified except in writing by Us.
              </li>
              <li>
                Access to some parts of the Site, and some of its features are
                provided to all the Users free of charge. We reserve the right
                to restrict access to any areas or features of the Site, without
                prior notice to any User.
              </li>
              <li>
                You agree that you shall not copy, reproduce or download any
                information, text, images, video clips, directories, files,
                databases or listings available on or through the Site for the
                purpose of re-selling or re-distributing, operating a business
                that competes with the Site, or otherwise commercially
                exploiting the Site’s Content. Systematic retrieval of Site’s
                Content to create or compile, directly or indirectly, a
                collection, database or directory or any such compilation
                (whether through bots, spiders, automatic devices, or manual
                processes) without prior written permission from Us is
                prohibited. In addition, use of the content or materials for any
                purpose not expressly permitted in this Agreement is prohibited.
              </li>
              <li>
                <strong>User’s Eligibility</strong>
                <p>
                  The use of the Site and application is available only to
                  persons who can enter into legally binding contracts under the
                  Indian Contract Act, 1872. Persons who are “incompetent to
                  contract” within the meaning of the Indian Contract Act, 1872
                  including minors, undischarged insolvents etc. Are not
                  eligible to this site.
                </p>
                <p>
                  If you are a minor, you shall not register as a vendor on the
                  website, transact or use the Site. As a minor if you wish to
                  use the Site or make a transaction, such use or transaction
                  may be made by your legal guardian or parents. Cynepia
                  reserves the right to terminate your registration if it is
                  brought to Cynepia notice or discovered that you are under the
                  age of 18 years.If you register as a business entity, you
                  represent that you are duly authorized by the business entity
                  to accept the terms of this agreement and you have the
                  authority to bind business entity to this agreement.
                </p>
              </li>
              <li>
                <strong>Account and Registration Obligations</strong>
                <p>
                  The User is solely responsible for maintaining confidentiality
                  of the User password and user identification and all
                  activities and transmission performed by the User through his
                  user identification and shall be solely responsible for
                  carrying out any online or off-line transaction involving
                  credit cards / debit cards or such other forms of instruments
                  or documents for making such transactions and Cynepia assumes
                  no responsibility or liability for their improper use of
                  information relating to such usage of credit cards / debit
                  cards used by the subscriber online / off-line. The User
                  agrees that if any information provided is untrue, inaccurate,
                  not current, incomplete, or not in compliance with this terms
                  of the agreement, we shall have the right to indefinitely
                  suspend, terminate or block you from accessing the website
                </p>
              </li>
              <li>
                <strong>Acceptable Use Policy</strong>
                <p>
                  The User / visitor to www.cynepia.com or affiliated site(s) is
                  prohibited from uploading, posting, transmitting, updating or
                  sharing any information that:
                </p>
                <ul>
                  <li>
                    belongs to another person and to which the user does not
                    have any right to;
                  </li>
                  <li>
                    is grossly harmful, harassing, blasphemous, defamatory,
                    obscene, pornographic, paedophilic, libellous, invasive of
                    another's privacy, hateful, or racially, ethnically
                    objectionable, disparaging, relating or encouraging money
                    laundering or gambling, or otherwise unlawful in any manner
                    whatever; harm minors in any way;
                  </li>
                  <li>
                    infringes any patent, trademark, copyright or other
                    proprietary rights;
                  </li>
                  <li>violates any law for the time being in force;</li>
                  <li>
                    deceives or misleads the addressee about the origin of such
                    messages or communicates any information which is grossly
                    offensive or menacing in nature; impersonate another person;
                  </li>
                  <li>
                    contains software viruses or any other computer code, files
                    or programs designed to interrupt, destroy or limit the
                    functionality of any computer resource; threatens the unity,
                    integrity, defence, security or sovereignty of India,
                    friendly relations with foreign states, or or public order
                    or causes incitement to the commission of any cognizable
                    offence or prevents investigation of any offence or is
                    insulting any other nation.
                  </li>
                  <li>Otherwise violates this Terms of Use.</li>
                </ul>
                <p>The Users are also not allowed to:</p>
                <ul>
                  <li>
                    Do anything that violates local, state, national or
                    international law or breaches any of your contractual
                    obligations or fiduciary duties.
                  </li>
                  <li>
                    Share your password, let anyone access your account, or do
                    anything that might put your account at risk.
                  </li>
                  <li>Attempt to access any other user's account.</li>
                  <li>
                    Reproduce, transfer, sell, resell, or otherwise misuse any
                    content from our services, unless specifically authorized to
                    do so.
                  </li>
                  <li>
                    Access, tamper with, or use non-public areas of our systems,
                    unless specifically authorized to do so.
                  </li>
                  <li>
                    Break or circumvent our authentication or security measures
                    or otherwise test the vulnerability of our systems or
                    networks, unless specifically authorized to do so.
                  </li>
                </ul>
              </li>
              <li>
                <strong>How Cynepia can use your content</strong>
                <p>
                  You grant Cynepia a non-exclusive, royalty-free, transferable,
                  sub-licensable, worldwide license to use, store, modify,
                  create derivative works, perform, and distribute your User
                  Content on Cynepia solely for the purposes of operating,
                  developing, providing, and using the Cynepia Products. Nothing
                  in these Terms shall restrict other legal rights Cynepia may
                  have to User Content, for example under other licenses. We
                  reserve the right to remove or modify User Content for any
                  reason, including User Content that we believe violates these
                  Terms or our policies.
                </p>
              </li>
              <li>
                <strong>Licences</strong>
                <p>You agree, acknowledge and understand that:</p>
                <ul>
                  <li>
                    You are using the Website provided and owned by Cynepia;
                  </li>
                  <li>
                    The permission granted by Cynepia to use the Website is on a
                    non-exclusive basis, non-transferable and non sub-licensable
                    license;
                  </li>
                  <li>
                    Cynepia reserves the right to deny access to, or revoke,
                    such permission to use the Website at any time;
                  </li>
                  <li>
                    Cynepia shall have the right to remove the listing of any
                    Product/Service being offered for sale by You;
                  </li>
                  <li>
                    Any and all data derived as a result of this Agreement will
                    be owned by Cynepia and You shall have the right to utilize
                    such data for the duration of the Term of this Agreement to
                    fulfill Your obligations hereunder; and
                  </li>
                  <li>
                    The User represents that the User shall not, at any time,
                    use any intellectual property of Cynepia in any manner
                    without the prior written consent of Cynepia. The User also
                    represents that the User shall not purchase any Cynepia
                    metatags on the Internet without the prior written consent
                    of Cynepia
                  </li>
                </ul>
              </li>
              <li>
                <strong>Data Privacy</strong>
                <p>
                  For collection, use and processing of personally identifiable
                  data of the User of the Cynepia Site, Cynepia shall comply
                  with applicable laws on data privacy protection and the
                  Cynepia Site’s Privacy Policy.
                </p>
              </li>
              <li>
                <strong>Registration Fee</strong>
                <p>
                  The Registration on the website is free. Cynepia do charge any
                  fee for browsing/registering on the website. However, before
                  you offer a service/product through the website.
                </p>
              </li>
              <li>
                <strong>Trademark, Copyright and Restriction</strong>
                <p>
                  This site is controlled and operated by Cynepia. All material
                  on this site, including images, illustrations, audio clips,
                  and video clips, are protected by copyrights, trademarks, and
                  other intellectual property rights. Material on the Website is
                  solely for Your personal, non-commercial use. You must not
                  copy, reproduce, republish, upload, post, transmit or
                  distribute such material in any way, including by email or
                  other electronic means, and whether directly or indirectly and
                  You must not assist any other person to do so. Without the
                  prior written consent of the owner, modification of the
                  materials, use of the materials on any other website or
                  networked computer environment, or use of the materials for
                  any purpose other than personal, non-commercial use is a
                  violation of the copyrights, trademarks and other proprietary
                  rights, and is prohibited. Any use for which You receive any
                  remuneration, whether in money or otherwise, is a commercial
                  use for the purposes of this clause.
                </p>
              </li>
              <li>
                <strong>Assignment</strong>
                <p>
                  The rights and obligations under this Agreement shall not be
                  assigned or transferred by You to any third party whomsoever,
                  during the Term of this Agreement.
                </p>
              </li>
              <li>
                <strong>Suspension and Termination</strong>
                <ol>
                  <li>
                    By Cynepia, with immediate effect, if you are in breach of
                    any of its obligations, representations or warranties, or
                    any other material terms as contained in this Agreement
                    and/or any of the Cynepia Policies;
                  </li>
                  <li>
                    By Cynepia, without any reason, by giving You a prior notice
                    of seven (7) days; or
                  </li>
                  <li>
                    By Cynepia, with immediate effect, if a petition for relief
                    under any bankruptcy or insolvency is filed by or against
                    You, or You make an assignment for the benefit of the
                    creditors, or a receiver or an administrative receiver or
                    administrator is appointed for all or a substantial part of
                    Your assets;
                  </li>
                </ol>
              </li>
              <li>
                <strong>Indemnity</strong>
                <p>
                  You shall indemnify and hold harmless www.cynepia.com , its
                  owner, licensee, affiliates, subsidiaries, group companies (as
                  applicable) and their respective officers, directors, agents,
                  and employees, from any claim or demand, or actions, including
                  reasonable attorneys' fees, made by any third party or penalty
                  imposed due to or arising out of Your breach of this User
                  Agreement and other Policies, or Your violation of any law,
                  rules or regulations or the rights (including infringement of
                  intellectual property rights) of a third party.
                </p>
              </li>
              <li>
                <strong>
                  Intellectual Property Rights Infringement Complaints
                </strong>
                <p>
                  The User agrees that the brands/logos, trademarks, etc., are
                  exclusive property of the Cynepia and cannot in any
                  circumstances be used, or copied, or altered in any manner.
                  You recognize and confirm that Cynepia has the exclusive right
                  to supervise, allow and reject the contents of the Website.
                  Cynepia shall not be liable for contents and images shared,
                  uploaded or displayed on the Website by the User and all
                  consequent liability will be borne by the User only.
                </p>
              </li>
              <li>
                <strong>Limitation of Liability</strong>
                <p>
                  THIS WEBSITE, ALL THE MATERIALS AND PRODUCTS (INCLUDING BUT
                  NOT LIMITED TO SOFTWARE) AND SERVICES, INCLUDED ON OR
                  OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE ARE PROVIDED
                  ON "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY REPRESENTATION
                  OR WARRANTIES, EXPRESS OR IMPLIED EXCEPT OTHERWISE SPECIFIED
                  IN WRITING. WITHOUT PREJUDICE TO THE FOREGOING PARAGRAPH,
                  CYNEPIA DOES NOT WARRANT THAT: THIS WEBSITE WILL BE CONSTANTLY
                  AVAILABLE, OR AVAILABLE AT ALL; OR THE INFORMATION ON THIS
                  WEBSITE IS COMPLETE, TRUE, ACCURATE OR NON-MISLEADING. CYNEPIA
                  WILL NOT BE LIABLE TO YOU IN ANY WAY OR IN RELATION TO THE
                  CONTENTS OF, OR USE OF, OR OTHERWISE IN CONNECTION WITH, THE
                  WEBSITE. CYNEPIA DOES NOT WARRANT THAT THIS SITE; INFORMATION,
                  CONTENT, MATERIALS, PRODUCT (INCLUDING SOFTWARE) OR SERVICES
                  INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE
                  WEBSITE; THEIR SERVERS; OR ELECTRONIC COMMUNICATION SENT FROM
                  US ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ALL THE
                  CONTENTS OF THIS SITE ARE ONLY FOR GENERAL INFORMATION OR USE.
                  THEY DO NOT CONSTITUTE ADVICE AND SHOULD NOT BE RELIED UPON IN
                  MAKING (OR REFRAINING FROM MAKING) ANY DECISION. ANY SPECIFIC
                  ADVICE OR REPLIES TO QUERIES IN ANY PART OF THE SITE IS/ARE
                  THE PERSONAL OPINION OF SUCH EXPERTS/CONSULTANTS/PERSONS AND
                  ARE NOT SUBSCRIBED TO BY THIS SITE. THE INFORMATION FROM OR
                  THROUGH THIS SITE IS PROVIDED ON "AS IS" BASIS, AND ALL
                  WARRANTIES, EXPRESSED OR IMPLIED OF ANY KIND, REGARDING ANY
                  MATTER PERTAINING TO ANY GOODS, SERVICE OR CHANNEL, INCLUDING
                  WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                  FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT ARE
                  DISCLAIMED AND EXCLUDED. CERTAIN LINKS ON THE SITE LEAD TO
                  RESOURCES LOCATED ON SERVERS MAINTAINED BY THIRD PARTIES OVER
                  WHOM CYNEPIA HAS NO CONTROL OR CONNECTION,BUSINESS OR
                  OTHERWISE AS THESE SITES ARE EXTERNAL TO WWW.CYNEPIA.COM YOU
                  AGREE AND UNDERSTAND THAT BY VISITING SUCH SITES YOU ARE
                  BEYOND THE WWW.CYNEPIA.COM WEBSITE. CYNEPIA THEREFORE NEITHER
                  ENDORSES NOR OFFERS ANY JUDGEMENT OR WARRANTY AND ACCEPTS NO
                  RESPONSIBILITY OR LIABILITY FOR THE AUTHENTICITY/AVAILABILITY
                  OF ANY OF THE GOODS/SERVICES/OR FOR ANY DAMAGE, LOSS OR HARM,
                  DIRECT OR CONSEQUENTIAL OR ANY VIOLATION OF LOCAL OR
                  INTERNATIONAL LAWS THAT MAY BE INCURRED BY YOUR VISIT AND/OR
                  TRANSACTION/S ON THESE SITES.
                </p>
              </li>
              <li>
                <strong>Applicable Law</strong>
                <p>
                  This user agreement shall be governed by and interpreted and
                  construed in accordance with the laws of India. The place of
                  jurisdiction shall be exclusively in Bangalore, KARNATAKA,
                  INDIA
                </p>
              </li>
              <li>
                <strong>Severability</strong>
                <p>
                  Any provision of this Agreement that is prohibited or
                  unenforceable in any jurisdiction will, as to such
                  jurisdiction, be ineffective to the extent of such prohibition
                  or unenforceability without invalidating the remaining
                  portions hereof or affecting the validity or enforceability of
                  such provision in any other jurisdiction.
                </p>
              </li>
              <li>
                <strong>Entire Agreement</strong>
                <p>
                  This Agreement, along with the other Cynepia Policies shall
                  constitute the full and complete Agreement between the User
                  and Cynepia hereto relating to the subject matter thereof.
                </p>
              </li>
              <li>
                <strong>Contact Us</strong>
                <p>
                  If you have any questions regarding this User Agreement,
                  please write to us to info@cynepia.com. Any feedback you
                  provide at this Site shall be deemed to be non-confidential.
                  We reserve the right to freely use such information on an
                  unrestricted basis.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
