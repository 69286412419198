import React, { Component } from "react";
import "./carouselDots.scss";

export default class CarouselDots extends Component {
	constructor(props) {
		super(props);
		this.currentRef = React.createRef();
	}

	setToActive(id) {
		const carouselDots =
			this.currentRef.current.querySelectorAll(".carouselDot");

		carouselDots.forEach((item, index) => {
			if (index === id) {
				item.classList.add("active");
			} else {
				item.classList.remove("active");
			}
		});

		this.props.clickCb(id);
	}

	render() {
		const arr = [...Array(this.props.length).keys()];
		return (
			<>
				<div
					className="carouselDots d-flex w-100 justify-content-center"
					id="carouselDots"
					ref={this.currentRef}
				>
					{arr.map((i, index) => {
						return (
							<div
								key={index}
								className="carouselDot cursor-pointer mx-2"
								onClick={() => {
									this.setToActive(index);
								}}
							></div>
						);
					})}
				</div>
			</>
		);
	}
}
