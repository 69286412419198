import React from "react";

import ListComponent from "../../../listComponent/listComponent";
import ImageSlideshow from "../../../ImageSlideshow";

const CreateAgentFlow = () => {
  return (
    <>
      <div className="page-section">
        <div className="page-container container">
          <div className="row align-items-start g-5">
            <div className="col-xxl-5 col-lg-6">
              <h6 className="pre-heading-highlight fw-bold">
                Evaluate your Agent
              </h6>
              <h3> Create & Run evaluations</h3>
              <ListComponent
                data={[
                  "Create Experiments using Agent Experiments",
                  "Out  of the box support for most common evaluation metrics including LLM as a judge",
                  "Versioned datasets ensure that you keep track of your test suite",
                  "Track all eval runs",
                  "Drill down into details of run specific information and further to test case run information",
                  "Fix the failures and iterate fast",
                ]}
              />
            </div>
            <div className="col-xxl-7 col-lg-6">
              <div className="d-inline rounded">
                <ImageSlideshow
                  images={[
                    "/images/evalMetrics1.png",
                    "/images/runHistory.png",
                    "/images/testCaseRunDetails.png",
                  ]}
                  timerLimit={3000}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section">
        <div className="page-container container">
          <div className="row align-items-start g-5">
            <div className="col-xxl-6 col-lg-6">
              <div className="img-wrapper text-center">
                <img
                  src="/images/AgentChatInterfaceDark.svg"
                  width="100%"
                  alt="Agent Chat iterface"
                />
              </div>
            </div>
            <div className="col-xxl-6 col-lg-6">
              <h6 className="pre-heading-highlight fw-bold">
                Easy to deploy and use
              </h6>
              <h3>One Click Deployment and Use</h3>
              <ListComponent
                data={[
                  "One click deployment ensures that agents can be deployed in production at required scale",
                  "Pre-integrated conversational UI makes agent instantly available to the users.",
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="page-section">
        <div className="page-container container">
          <div className="row align-items-start g-5">
            <div className="col-xxl-6 col-lg-6">
              <h6 className="pre-heading-highlight fw-bold">Monitoring</h6>
              <h3>Monitoring Post Deployment</h3>
              <ListComponent
                data={[
                  "Support for pre-integrated agent observability",
                  "Out of the box support for most common consumption metrics, trends, consumption by task/status",
                  "Filter & Drill down into details by date/task/status",
                  "Review details for a specific conversation thread",
                ]}
              />
            </div>
            <div className="col-xxl-6 col-lg-6">
              <div className="img-wrapper text-center">
                <img
                  src="/images/MetricsDashboardDark.svg"
                  width="100%"
                  alt="Data agents for all roles"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section">
        <div className="page-container container">
          <div className="row g-5">
            <div className="col-xxl-6 col-lg-6">
              <div className="img-wrapper text-center">
                <img
                  src="/images/dataPreparationVisual3.svg"
                  width="100%"
                  alt="cynepia data connectors"
                  className="rounded-4"
                  style={{ border: "8px solid black" }}
                />
              </div>
            </div>

            <div className="col-xxl-5 col-lg-6">
              <h6 className="pre-heading-highlight fw-bold">
                Collaborate across all data users
              </h6>
              <h3>Govern, Share and Collaborate</h3>
              <ListComponent
                data={[
                  "Share your agents with users who need access",
                  "Granular access control & governance ensures users have priviledges they need",
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAgentFlow;
