import React from "react";

const SemanticSearchSection = () => {
  const data = [
    "Smart Search using Keyword Matching & Semantic Understanding via vector search.",

    "360 degree visibility of all your enterprise data and AI assets from a single page.",

    "Governed & Secure search ensures users can only discover the assets they have access to.",
  ];
  return (
    <div className="page-section" id="Text2SQLSection">
      <div className="page-container container rounded py-3">
        <div className="row align-items-center justify-content-between g-5 p-5">
          <div className="col-xxl-6 col-lg-6 ">
            <h3 className="">
              Smart AI Search & Discovery across all your Data & AI Asset
            </h3>

            <div className="d-flex flex-column gap-3">
              {data.map((item, i) => {
                return (
                  <div key={i} className="border white px-3 py-3 rounded">
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-xxl-5 col-lg-5">
            <img
              src="/images/searchFeature.svg"
              width="100%"
              alt="cynepia data connectors"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SemanticSearchSection;
