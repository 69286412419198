import React, { Component } from "react";
import ContactUsSection from "../../../contactUsSection/contactUsSection";
import CtaButtons from "../../../ctaButtons/ctaButtons";
import ListComponent from "../../../listComponent/listComponent";

export default class SelfServiceBI extends Component {
  render() {
    return (
      <div className="page" id="selfServiceBIPage">
        <div className="page-section">
          <div className="page-container container hero-container">
            <div className="row align-items-center g-5">
              <div className="col-lg-5 col-md-12 mb-4">
                <h1 className="mb-3">
                  Transtion Into Data Driven Decision Future
                </h1>
                <p className="fs-5 mb-5">
                  Create dashboards and reports charts instantly. Stop using
                  multiple dashboarding tools
                </p>
                <CtaButtons />
              </div>
              <div className="col-lg-7 col-md-12 h-100 right-section">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/selfServiceBiBanner.png"
                    width="100%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row">
              <div>
                <h2>Dont leave any story behind</h2>
                <p className="fs-5">
                  Our powerful adhoc story designer lets users design compelling
                  data stories using charts and maps that help decision makers
                  quickly grasp the generated insights. An interactive dashboard
                  provides a variety of options for designing dynamic, mobile
                  friendly dashboard. Any Users with limited technical skills,
                  can author, view and engage with these data stories easily and
                  instantly.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-start g-5">
              <div className="col-xxl-6 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                  Create Data Driven Culture
                </h6>
                <h3>Empower everyone in your organization to use data</h3>
                <ListComponent
                  data={[
                    "Removes friction since all data is in one place.",
                    "Create visually appealing stories from a wide range of charts and visualizations.",
                    "Even non-technical users can now build data stories on their own.",
                  ]}
                />
              </div>
              <div className="col-xxl-6 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/selfServiceBiVisual1.svg"
                    width="100%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section">
          <div className="page-container container">
            <div className="row">
              <div className="col-xxl-7 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataPreparationVisual3.svg"
                    width="100%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
              <div className="col-xxl-5 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                  All in one place
                </h6>
                <h3>Govern, Share and Collaborate</h3>
                <ListComponent
                  data={[
                    "Ensure that everyone has access to correct data.",
                    "Distributed Governance ensures instant access of data to users who need it.",
                    "Notify users on events.",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="page-section">
          <div className="page-container container">
            <h3 className="mb-5">Technical Capabilties</h3>
            <div className="row g-3 align-items-strech justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/dashboard.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Interactive Dashboards</h5>
                    <div className="description">
                      Create, organize and share powerful executive dashboards
                      by role or departments in minutes.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/pdfReports.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Reports</h5>
                    <div className="description">
                      Download or Schedule PDF version of a workspace as report.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/filter.svg"
                        alt="cynepia historical snapshot"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Filter / Drilldown</h5>
                    <div className="description">
                      Filter and Drilldown at various levels and hierachy.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/desktopVisualization.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Advanced Visualization</h5>
                    <div className="description">
                      Wide range of charts and maps to select from and present
                      the data.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/badge.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">
                      Flexible Online Dashboard Authoring
                    </h5>
                    <div className="description">
                      Flexible and easy to use web-based authoring too available
                      to everyone to create and share reports .
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/mobileAnalytics.svg"
                        alt="cynepia data connector"
                        width={70}
                      />
                    </div>
                    <h5 className="title">Mobile View</h5>
                    <div className="description">
                      Responsive Design enables dashboard on different device
                      formats.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/memoryAnalytics.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">In-memory analytics</h5>
                    <div className="description">
                      Connect to data directly in data lake and build insights
                      instantly.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/bellNotify.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Data Alerts</h5>
                    <div className="description">
                      Notify users of events of interest.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/automatedRefreshGear.svg"
                        alt="cynepia data connector"
                        width="75"
                      />
                    </div>
                    <h5 className="title">Automated refresh</h5>
                    <div className="description">
                      Automated dashboard refresh, once the new version of the
                      underlying data is available.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactUsSection />
      </div>
    );
  }
}
