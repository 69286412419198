import React, { Component } from "react";
import ContactUsSection from "../../../contactUsSection/contactUsSection";
import CtaButtons from "../../../ctaButtons/ctaButtons";
import ListComponent from "../../../listComponent/listComponent";

export default class SmartSearch extends Component {
  render() {
    return (
      <div className="page" id="selfServiceBIPage">
        <div className="page-section">
          <div className="page-container container hero-container">
            <div className="row align-items-center justify-content-between g-5">
              <div className="col-lg-5 col-md-12 mb-4">
                <h1 className="mb-3">
                  Smart AI Search & Discovery across all your Data & AI Asset
                </h1>
                <p className="fs-5 mb-5">
                  Improved User Experience via Smart AI Search helps data
                  consumers can easily access relevant data assets.
                </p>
                <CtaButtons />
              </div>
              <div className="col-lg-7 col-md-12 h-100 right-section">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/searchBanner.svg"
                    width="90%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row">
              <div>
                <h2>
                  Explore all entire data assets in minutes, Find relevant asset
                  you need quickly, save time searching
                </h2>
                <p className="fs-5">
                  Be it data connectors, datasets in your data catalog, model
                  registry, jobs, SQL models, python models or no code
                  workflows, We have all your data landscape covered, Discover
                  all your data from a single view.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-start g-5">
              <div className="col-xxl-6 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                  SEARCH WHAT YOU NEED, WHEN YOU NEED
                </h6>
                <h3>One place to find all your data assets</h3>
                <ListComponent
                  data={[
                    "Smart Search using Keyword Matching & Semantic Understanding via vector search.",
                    "360 degree visibility of all your enterprise data and AI assets from a single page.",
                    "Governed & Secure search ensures users can only discover the assets they have access to.",
                  ]}
                />
              </div>
              <div className="col-xxl-6 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/searchFeature.svg"
                    width="100%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section">
          <div className="page-container container">
            <h3 className="mb-5">Technical Capabilties</h3>
            <div className="row g-3 align-items-strech justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/360degree.svg"
                        alt="cynepia data connector"
                        width={70}
                      />
                    </div>
                    <h5 className="title">360 Degree Visibility</h5>
                    <div className="description">
                      Discover all your enterprise Data & AI assets from a
                      single page.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/vectorIndexing.svg"
                        alt="cynepia data connector"
                        width={70}
                      />
                    </div>
                    <h5 className="title">Automatic Vector Indexing</h5>
                    <div className="description">
                      Automatically generate embeddings using popular open
                      source embedding modals and vector databases.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4 h-100">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/filter.svg"
                        alt="cynepia historical snapshot"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Filter by Asset Type</h5>
                    <div className="description">
                      Only fetch assets that match a given asset type.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/vectorSearch.svg"
                        alt="cynepia data connector"
                        width={65}
                      />
                    </div>
                    <h5 className="title">Vector & Keyword Search</h5>
                    <div className="description">
                      A combination of semantic search and keyword search to
                      improve search relevance
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/peopleGroup.svg"
                        alt="cynepia data connector"
                        width={50}
                      />
                    </div>
                    <h5 className="title">Governed & Secure</h5>
                    <div className="description">
                      Granular Governance ensures everyone has access to what is
                      required for their specific role.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="card h-100 no-shadow p-4">
                  <div className="d-flex flex-direction-column ">
                    <div className="img-wrapper mb-4">
                      <img
                        src="/images/icons/dollarThree.svg"
                        alt="cynepia data connector"
                        width={70}
                      />
                    </div>
                    <h5 className="title">Save Cost</h5>
                    <div className="description">
                      Hosted Open source embedding model, vector database
                      ensures no additional provider cost while users search
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactUsSection />
      </div>
    );
  }
}
