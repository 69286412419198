import React, { Component } from "react";
import ContactUsSection from "../../../contactUsSection/contactUsSection";
import CtaButtons from "../../../ctaButtons/ctaButtons";
import ListComponent from "../../../listComponent/listComponent";
import "./sqlEditorView.scss";

export default class SQLEditorView extends Component {
  render() {
    return (
      <div className="page" id="dataEngineeringAndMlPage">
        <div className="page-section">
          <div className="page-container container hero-container">
            <div className="row align-items-center g-3">
              <div className="col-lg-12 col-md-12 text-center mb-4">
                <h1 className="mb-3">Create {"&"} Run SQL Queries directly on your datalake</h1>
                <p className="fs-5 mb-4">
                Get answers to your queries instantly.
                </p>
                <CtaButtons center={true} />
              </div>
              <div className="col-lg-12  col-md-12 h-100 right-section">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/sqlEditorBanner.svg"
                    width="80%"
                    alt="cynepia SQL Editor"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row">
              <div>
                <h2>Overview</h2>
                <p className="fs-5">
	        Build SQL Models and perform ad-hoc queries right within your
                Xceed Analytics Workspace. Xceed SQL Workbench
                is designed to boost analyst productivity.
                Integrated with data catalog, readily accessible schemas,
                summary statistics and auto-completion features enable
                analyst to write queries with ease. Additionally,
                Xceed Analytics integrates and makes available a variety of
                execution engines/environments ensuring that analyst need not
                worry about availability of these execution environments.
                It enables your analyst to focus back on extracting value from your
                data assets and create high quality SQL models.
                Collaborate and share the SQL models instantly effortlessly
                with other analysts.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row align-items-start g-5">
              <div className="col-xxl-5 col-lg-5">
                <h6 className="pre-heading-highlight fw-bold">
                  OPTIMIZE FOR YOUR RUNNING COST
                </h6>
                <h3>
                  No need to worry about cost of your SQL warehouse platform
                </h3>
                <ListComponent
                  data={[
                    "No need to worry about cost of your SQL warehouse platform.",
                    "Multi-engine supports including open-source engines help analyst pick the engine that is optimal for the requirement.",
                  ]}
                />
              </div>
              <div className="col-xxl-7 col-lg-7">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/sqlEditorVisual2.svg"
                    width="100%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container ">
            <div className="row">
              <div className="col-xxl-7 col-lg-7">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/sqlEditorVisual1.svg"
                    width="90%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
              <div className="col-xxl-5 col-lg-5">
                <h6 className="pre-heading-highlight fw-bold">
                  ANALYST WORKBENCH BUILD FOR PRODUCTIVITY
                </h6>
                <h3>Build SQL Models</h3>
                <ListComponent
                  data={[
                    "Create Reusable Models And Store the Models as Views",
                    "Virtualize and Store results directly onto Datalake.",
                    "Readily available data profile of the query output.",
                    "Make the transformed data available instantly via Enterprise Data Catalog for Consumption"
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row g-5">
              <div className="col-xxl-5 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                COLLABORATE WITH OTHER ANALYSTS USERS
                </h6>
                <h3>Govern, Share and Collaborate</h3>
                <ListComponent
                  data={[
                    `Share your data models across all the data teams who need 
                    access to your model instantly`,
                    `Never start from scratch. Reuse and Build newer models on 
                    top of existing SQL models.`
                  ]}
                />
              </div>
              <div className="col-xxl-7 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataPreparationVisual3.svg"
                    width="90%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="page-container container">
            <div className="row g-5">
              <div className="col-xxl-7 col-lg-6">
                <div className="img-wrapper text-center">
                  <img
                    src="/images/dataPreparationVisual4.svg"
                    width="100%"
                    alt="cynepia data connectors"
                  />
                </div>
              </div>
              <div className="col-xxl-5 col-lg-6">
                <h6 className="pre-heading-highlight fw-bold">
                AUTOMATE TO ACCELARATE
                </h6>
                <h3>Automate View Refresh</h3>
                <ListComponent
                  data={[
                    `Schedule refresh of stored views.`,
                    `Accelerate time to value for your insights.`,
                    `Run Periodically or Trigger SQL Job on Input Dataset Update, 
                      Alert stack-holders for any SQL Model run failures.`
                  ]}
                />
              </div>
            </div>
          </div>
        </div>

        <ContactUsSection />
      </div>
    );
  }
}
