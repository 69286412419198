import React, { Component } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as SelfServiceBISvg } from "./featureSetIcons/selfServiceBi.svg";
import { ReactComponent as ScalableArchitecture } from "./featureSetIcons/scalableArchitecture.svg";
import { ReactComponent as Flexible } from "./featureSetIcons/flexible.svg";
import { ReactComponent as SecureDatabase } from "./featureSetIcons/secureDatabase.svg";
import { ReactComponent as StayConnected } from "./featureSetIcons/stayConnected.svg";
import { ReactComponent as BroaderConnectivity } from "./featureSetIcons/broaderConnectivity.svg";
import { ReactComponent as Governance } from "./featureSetIcons/governance.svg";
import { ReactComponent as ApiSupport } from "./featureSetIcons/apiSupport.svg";
import { ReactComponent as GlobalSupport } from "./featureSetIcons/globalSupport.svg";

export default class FeatureSet extends Component {
  static getContents() {
    return [
      {
        imgSrc: "images/icons/RobotIcon.svg",
        title: "Data Agent",
        description: "Truly self-serve data-agent built for business teams",
        width: 80,
        action: {
          link: "/products/ai-agents",
        },
      },
      {
        ImgIcon: SelfServiceBISvg,
        title: "Self-Service BI",
        description:
          "Quick & Easy to analyze data and build personalized storyboards.",
        width: 60,
        action: {
          link: "/products/self-service-bi",
        },
      },
      {
        ImgIcon: ScalableArchitecture,
        title: "Scalable Architecture",
        description:
          "Micro-services coupled with in-memory analytics engine ensures scalability.",
        width: 60,
        action: {
          link: "/products/architecture",
        },
      },
      {
        ImgIcon: Flexible,
        title: "Flexible Engine",
        description:
          "Flexible Choice of Analytics Engine support your data size needs.",
        width: 55,
        action: {
          link: "/products/architecture",
        },
      },
      {
        ImgIcon: SecureDatabase,
        title: "Single Tenent",
        description:
          "Stay in control of your data ownership, privacy and security requirements.",
        width: 50,
      },
      {
        ImgIcon: StayConnected,
        title: "Stay Connected",
        description: "Access your data and insights even while on the move",
        width: 60,
      },
      {
        ImgIcon: BroaderConnectivity,
        title: "Broader Connectivity",
        description:
          "Supports both streaming and batch data sources including Twitter",
        width: 60,
        action: {
          link: "/products/data-connectors",
        },
      },
      {
        ImgIcon: Governance,
        title: "Data Governance",
        description:
          "Ensure secure user access and privileges based on user roles.",
        width: 60,
        action: {
          link: "/products/architecture",
        },
      },
      {
        ImgIcon: ApiSupport,
        title: "API Support",
        description:
          "Build and integrate with existing enterprise applications.",
        width: 60,
      },
      {
        ImgIcon: GlobalSupport,
        title: "Global Support",
        description:
          "Build and integrate with existing enterprise applications.",
        width: 60,
      },
    ];
  }

  render() {
    return (
      <div className="page-section">
        <div className="page-container container secondary-bg p-5">
          <div className="px-3">
            <h6 className="pre-heading-highlight fw-bold mt-5 text-primary mb-3">
              Key Capabilities
            </h6>
            <h3 className="col-lg-6  mb-5">
              Empower Citizen Data Engineers & Scientists to enable powerful
              insights.
            </h3>
            <div className="row g-3 justify-content-center align-items-stretch mb-5">
              {FeatureSet.getContents().map((item, i) => {
                const { ImgIcon, imgSrc } = item;
                return (
                  <div
                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-10"
                    key={i}
                  >
                    <div className="feature-block no-shadow h-100 hover-scale-1">
                      <div className="content h-100 display-flex flex-direction-column">
                        <div className="img-wrapper mb-3 p-3 h-20 d-flex align-items-center">
                          {ImgIcon ? (
                            <ImgIcon
                              style={{ fill: "#fff", width: item.width }}
                            />
                          ) : (
                            ""
                          )}
                          {imgSrc ? (
                            <img
                              style={{ width: item.width }}
                              src={imgSrc}
                              alt={item.title}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="card-body h-70 d-flex flex-direction-column">
                          <h6 className="title">{item.title}</h6>
                          <div className="description mb-3">
                            {item.description}
                          </div>
                          {item.action ? (
                            <Link to={item.action.link} className="">
                              <button className="button primary small arrow-link">
                                {item.action.title
                                  ? item.action.title
                                  : "Learn More"}
                              </button>
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
