import React, { Component } from "react";

export default class FindExploreAndGovern extends Component {
  render() {
    return (
      <div className="page-section">
        <div className="page-container container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="page-section">
                <div className="page-container container-flui">
                  <h2>Challenge</h2>
                  <p>
                    Too much time discovering trusted data assets (including
                    monthly data) across various individual repositories across
                    various departments and SAAS applications.
                  </p>
                  <p>
                    The data analyst team and business users needed a more
                    efficient way to discover data & query datasets and find
                    answers to data questions. Often getting answers to data
                    wasn’t easy, since data was stored in various siloed
                    repositories. The problem becomes particularly complex and
                    challenging, in a high growth company with multiple
                    departments each using quite a few different saas tools and
                    each generating data at a rapid velocity. Number of datasets
                    usually grow to hundreds and even thousands across these
                    siloed databases/applications making it highly inefficient
                    for a data user or data analyst to discover or find the
                    data. Often you need to know who to ask or which team within
                    the organisation has access to the data you need. Even if
                    you were to find out and have access to the required data
                    source, there often are issues with trust worthiness of a
                    given data snapshot, since the historical states are often
                    archived and stored and person who can vet such data
                    snapshots may not be accessible, leaving the possibility of
                    picking an inaccurate or mismanaged dataset.
                  </p>
                </div>
              </div>
              <div className="page-section">
                <div className="page-container">
                  <h2>Solution</h2>
                  <h5>Home for all your data assets</h5>
                  <p>
                    Automated Xceed Data Catalog acts as a central interface for
                    accessing any dataset across the company. Be it raw tables
                    from various databases, or csvs or files from local or cloud
                    repository. Once connected/uploaded to Xceed Data Lake,
                    Xceed Analytics automatically learns the metadata/schema of
                    the dataset ingested, which can further be updated by the
                    data engineer/analyst/owner for any changes. Post the
                    initial version of the metadata. Data
                    Owner/Analyst/Engineers can further document in detail and
                    add tags to ensure any one who has access to dataset can
                    quickly understand what is the dataset about.
                  </p>
                  <p>
                    Xceed Data Workflow Outputs are automatically updated and
                    accessible via the Data Catalog instantly.
                  </p>
                  <h5>Versioned Data Management</h5>
                  <p>
                    Xceed further creates/updates a new version of the data,
                    with every Scheduled pull or upload ensuring that historical
                    snapshots are accessible all the time along with governed
                    data.{" "}
                  </p>
                  <h5>Track Schema Changes </h5>
                  <p>
                    Any schema changes at the source or data anomalies are
                    alerted to prompt the data owner for action.{" "}
                  </p>
                  <h5>Data Preview & Column level descriptive statistics</h5>{" "}
                  <p>
                    For the latest version of the dataset, Catalog also
                    generates and shows column level profile statistics
                    providing a quick overview of individual column profiles.
                  </p>
                  <h5>Query Interface </h5>
                  <p>
                    Data Analyst can further filter, group and aggregate data
                    right from the data catalog using the SQL interface and save
                    the queries for future reuse.
                  </p>
                  <h5>Governed Data Assets </h5>
                  <p>
                    Dataset owner can ensure visibility of data only to users
                    who need them. Row Level filters further control visibility
                    at a record level for various BI dashboards/Download access.
                  </p>
                </div>
              </div>
              <div className="page-section">
                <div className="page-container">
                  <h2>Summary</h2>
                  <p>
                    In summary, an integrated data catalog not just acts as a
                    central knowledge repository of all your data assets and
                    make it easily for every one who needs access to find,
                    search and discover data assets, It eliminates the manual
                    process by providing a workflow integrated data catalog
                    ensuring, you get rid of manual process and thereby don’t
                    create technical debt by having to integrate disparate data
                    apps with the data catalog.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
