import React from "react";
import "./styles.scss";
import ImageSlideshow from "../../../ImageSlideshow";

function Text2SQLSection() {
  const data = [
    "Boost data analyst productivity in day to day work",
    "Generate SQL using natural language question",
    "Embedded within Analyst SQL workbench, no new tool or workspace"
  ];
  return (
    <div className="page-section" id="Text2SQLSection">
      <div className="page-container container rounded py-3">
        <div className="row align-items-start justify-content-between g-5 p-5">
          <div className="col-xxl-6 col-lg-6 ">
            <h3 className="">
              A comprehensive AI copilot stack for data teams
            </h3>

            <div className="d-flex flex-column gap-3">
              {data.map((item, i) => {
                return (
                  <div key={i} className="border white px-3 py-3 rounded">
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-xxl-5 col-lg-5">
            <ImageSlideshow
              images={[
                "/images/AiChatExample1.svg",
                "/images/AiChatExample2.svg",
                "/images/AiChatExample3.svg",
                "/images/AiChatExample4.svg",
              ]}
              timerLimit={3000}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Text2SQLSection;
